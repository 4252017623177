<template>
<div>
  <v-expansion-panels v-model="panel" flat >
    <v-expansion-panel
    @click="showOrders(factor.factor_ref)"
    v-for="factor in factors" :key="factor.factor_id"
    >
      <v-expansion-panel-header>
        <v-row>
          <v-col cols="5">
            <span class="medium-font">{{$t('bill.orderDate')}} : </span>
            <span class="medium-font numbers-font" v-text="factor.created_date"></span>
          </v-col>
          <v-col cols="5">
            <span class="medium-font">{{$t('bill.cargo')}} : </span>
            <span class="medium-font numbers-font" v-text="factor.cargo_count"></span>
          </v-col>
        </v-row>
        <template v-slot:actions>
          <v-icon color="primary">
          $expand
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col cols="12" md="5">
            <cost/>
          </v-col>
          <v-divider vertical class="mx-1 my-3 web-view"></v-divider>
          <v-divider class="mx-1 my-3 mobile-view"></v-divider>
          <v-col cols="12" md="5">
            <receiver />
          </v-col>
        </v-row>
        <v-list nav dense class="pa-0 pt-2">
        <v-list-item-group color="primary">
          <v-list-item v-for="(cargo, index) in cargos" :key="cargo.cargo_id" class="px-1" @click="modal = !modal">
            <v-list-item-icon class="mx-2">
              <v-icon>mdi-truck-fast</v-icon>
            </v-list-item-icon>
            <v-list-item-content @click="show_cargo(cargo.cargo_id)">
              <v-list-item-title>{{$t('bill.items')}}<span class="numbers-font" v-text="index + 1"></span></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
  <div class="modal-overlay" @click.self="modal = !modal" v-if="modal">
    <cargo/>
  </div>
</div>
</template>
<script>
import cost from './costDetail'
import receiver from './recieverDetail'
import { getcargos, factorviweDetail, viewCargo } from '@/views/adminPanel/models/factor'
import cargo from './cargoDetailModal'
import { mapState } from 'vuex'
export default {
  props: ['factors'],
  components: {
    cost,
    receiver,
    cargo
  },
  data: () => ({
    modal: false,
    panel: [],
  }),
  computed: {
    ...mapState({
      cargos: state => state.factors.cargos
    })
  },
  methods: {
    showOrders (ref) {
      getcargos(ref)
      factorviweDetail(ref)
    },
    show_cargo (cargo_id) {
      viewCargo(cargo_id)
    }
  }
}
</script>