import axios from "axios"
import Store from '@/store/index'
import { search_product_tag } from "./search"

type pro = {
  Is_Bulk: string;
  id: string;
  image: string;
  onhand: string;
  title: string;
  unit_title: string;
  Min_Order: string;
  Max_Order: string;
  Lot_Size: string;
  Prod_Price: string | number;
  priceFormate: string;
  Prod_Gross_Price: string | number;
  priceGrossFormate: string;
  org_id: string;
}
export function viewblock (blockID: string, parentID: string) {
  axios.post('/api/Store/Blocks/products',{
    "block_id" : blockID
  }).then(Response => {
  Store.dispatch('load_page', false)
  if (Response.data.Result.error) {
    Store.dispatch("alertSuccess",{
      show: true,
      type: 'error',
      typeMessage:'network',
      color:"error"
    })
    setTimeout(() => {
      Store.dispatch("alertSuccess",{
        show: false,
        type: 'success',
        typeMessage:'network',
        color:"success"
      })
    }, 3000);
    return
  }
  Store.dispatch('blockProduct', {products: Response.data.Result, parentID: parentID})
}).catch (() => {
  // Store.dispatch('alertError', {show: true, type: 'network'})
})
}
export function getblock () {
  Store.dispatch('load_page', true)
      axios.post('/api/app/home',{
      }).then(Response => {
        if (Response.data.Result.error) {
          Store.dispatch("alertSuccess",{
            show: true,
            type: 'error',
            typeMessage:'network',
            color:"error"
          })
          setTimeout(() => {
            Store.dispatch("alertSuccess",{
              show: false,
              type: 'success',
              typeMessage:'network',
              color:"success"
            })
          }, 3000);
          return
        }
        const products = Response.data.Result.blocks
        Store.dispatch('blocks', products)
        const _conf = localStorage.getItem("config") 
        if (_conf) {
          const config = JSON.parse(_conf)
          viewblock(config.special_offer_block_id, config.special_offer_block_id)
        }
      }).catch (() => {
        // Store.dispatch('alertError', {show: true, type: 'network'})
    })
}

export function blockList (parentID: string) {
  axios.post('/api/Store/Blocks/list',{
    "parent_id": parentID
  }).then(Response => {
    if (Response.data.Result.error) {
      Store.dispatch("alertSuccess",{
        show: true,
        type: 'error',
        typeMessage:'network',
        color:"error"
      })
      setTimeout(() => {
        Store.dispatch("alertSuccess",{
          show: false,
          type: 'success',
          typeMessage:'network',
          color:"success"
        })
      }, 3000);
      return
    }
    const products = Response.data.Result
    viewblock(products[0].id, parentID)
    Store.dispatch('selected_list', {index: 0, block_id: parentID})
    Store.dispatch('blocksList', {blockList: products, blockID: parentID})
  }).catch (() => {
    // Store.dispatch('alertError', {show: true, type: 'network'})
})
}

export function galleries (gallerieID: string, state: string) {
  axios.post('/api/Social/Galleries/getGalleryItems',{
    gallery_id: gallerieID
  }).then(Response => {
    if (Response.data.Result.error) {
      Store.dispatch("alertSuccess",{
        show: true,
        type: 'error',
        typeMessage:'network',
        color:"error"
      })
      setTimeout(() => {
        Store.dispatch("alertSuccess",{
          show: false,
          type: 'success',
          typeMessage:'network',
          color:"success"
        })
      }, 3000);
      return
    }
    Store.dispatch(state, Response.data.Result)
  }).catch (() => {
    // Store.dispatch('alertError', {show: true, type: 'network'})
})
}
export function group_tags(parent: string) {
  axios.get(`/api/store/tags/getTaxonomies/${parent}`).then(Response => {
    let filters: any = localStorage.getItem('filter_search')
    const category = Response.data.Result
        if (filters) {
          filters = JSON.parse(filters)
          filters.page = 1
          let tages = category[0].To_Tag
          if (category.length > 1) {
            for (const key of category) {
              tages = key.To_Tag + ',' + tages
            }
          }
          filters.tags = tages
          localStorage.setItem('filter_search', JSON.stringify(filters))
            search_product_tag(filters)
        } else {
          filters = {}
          filters.page = 1
          let tages = category[0].To_Tag
          if (category.length > 1) {
            for (const key of category) {
              tages = tages + ',' + key.To_Tag
            }
          }
          filters.tags = tages
          localStorage.setItem('filter_search', JSON.stringify(filters))
            search_product_tag(filters)
        }
  })
}
