<template>
  <v-card class="pa-2 card flat-card" color="surface">
    <v-row class="d-flex align-center justify-start pa-2">
      <v-col cols="8" class="pa-1">
      <v-text-field v-model="coupon" max="21" type="text" class="pa-1 medium-font mr-2" :placeholder="$t('basket.discountCode')"></v-text-field>
      </v-col>
      <v-col class="pa-1">
      <v-btn @click="checkCoupon()" class="ml-2 mb-3 button small-button onPrimaryActions--text primaryActions">{{$t('basket.validation')}}</v-btn>
      </v-col>
    </v-row>
    <v-row  v-if="coupons" class=" mt-2 pb-3 mx-2">
      <span class="medium-font ml-auto mr-3 onSurface--text">{{$t('basket.creditDeducted')}} : </span>
      <span class="medium-font numbers-font primaryText--text ml-3 mr-auto" v-text="coupons" ></span>
    </v-row>
    <v-row class="text-center mt-2 pb-3 mx-2">
      <span class="medium-font error--text mx-3" v-text="error_coupon"></span>
    </v-row>
  </v-card>
</template>

<script>
import store from '@/store'
import { coupon } from '@/views/shop/models/basket'
import { mapState } from 'vuex'
import { changeTypePrice_format } from '@/models/changeType'
export default {
  data: () => ({
    coupon: '',
    coupon_error: false,
    tah: 0
  }),
  computed: {
    ...mapState({
      coupons: state => state.basket.Tah_coupon,
      purses: state => state.basket.purses,
      price_coupon: state => state.basket.price_offer_coupon,
      error_coupon: state => state.basket.error_coupon,
      refresh: state => state.basket.refresh
    }),
    offer () {
      const offer = changeTypePrice_format({
        price: this.price_coupon,
        Tax_price: 0,
      })
      return offer
    }
  },
  watch: {
    price_coupon: {
      handler: function () {
        if (this.refresh) {
          for (const purse of this.purses) {
              if (purse.use) {
                store.dispatch('cal_purse', purse)
              }
          }
        }
      }
    }
  },
  methods: {
    checkCoupon () {
      coupon(this.coupon)
    }
  }
}
</script>