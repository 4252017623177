<template>
  <div class="d-flex flex-column align-center mt-5">
    <v-row class="pa-2 d-flex justify-center">
      <v-img width="100" :src="logo"></v-img>
    </v-row>
    <v-row class="d-flex flex-column">
      <div>
        <alert/>
      </div>
      <v-form @submit.prevent="forget()" ref="forget_pass">
      <v-card class="card flat-card pa-3" color="surface" width="400">
        <v-row>
          <v-col class="pb-0">
            <span class="primaryText--text lg-font">فراموشی رمزعبور</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0 pb-1">
            <v-text-field
              :rules="[rules.required, rules.mobile, rules.type_number]"
              v-model="mobile"
              label="شماره همراه"
              maxlength="11" type="tell"
              required
              prepend-icon="mdi-cellphone"/>
          </v-col>
        </v-row>
        <captcha @capchaing="capchaing"/>
        <v-card-actions class="px-0 mt-3">
          <v-btn @click="check" :disabled="sub" class="onPrimaryActions--text primaryActions button w-100" type="submit">
            ارسال رمزعبور
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-row>
  </div>
</template>
<script>
import captcha from '@/components/captcha/captcha'
import { alertMessage } from '@/assets/messageAlert'
import { toEnglishDigits } from '@/models/changeType'
import alert from '@/components/alerts/alert'
import { forget_password } from './models/auth'
import { mapState } from 'vuex'
import store from '@/store'
export default {
  components: {
    alert,
    captcha
  },
  data: () => ({
    logo: '',
    mobile: '',
    cap: false,
    rules: {
      required: value => !!value || alertMessage['required'],
      mobile: value => {
        const mobile = toEnglishDigits(value)
        return mobile.slice(0,2) == '09' || alertMessage['typePhone']},
        type_number: value => {
        const mobile = toEnglishDigits(value)
        const pattern = /^[0-9]+$/
        return pattern.test(mobile) || alertMessage['type_number']
      },
    }
  }),
  computed: {
    ...mapState({
      loading: state => state.loading,
      send_code: state => state.auth.send_code,
      sub: state => state.loadingSubmit 
    })
  },
  created () {
    this.logo = localStorage.getItem('logo_site')
  },
  methods: {
    capchaing (cap) {
      this.cap = cap
    },
    check () {
      this.rules.required =
      value => !!value || alertMessage['require']
    },
    forget () {
      const validation = this.$refs.forget_pass.validate()
      if (this.cap) {
        if (validation == true) {
      forget_password(toEnglishDigits(this.mobile))
      }
      }  else {
        scrollTo(0,0)
        store.dispatch("alertSuccess",{
      show: true,
        type: 'error',
        typeMessage:'captcha',
        color:"error"
    })
    setTimeout(() => {
      store.dispatch("alertSuccess",{
      show: false,
        type: 'error',
        typeMessage:'network',
        color:"error"
    })
    }, 2500);
      }
    }
  }
}
</script>