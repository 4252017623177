<template>
  <div class="d-flex flex-column align-center mt-5">
    <v-row class="pa-2 d-flex justify-center">
      <v-img width="100" :src="logo"></v-img>
    </v-row>
    <v-row class="d-flex flex-column">
      <div>
        <error/>
      </div>
      <v-form @submit.prevent="confirm" ref="confirm">
        <v-card class="card flat-card" color="surface" width="400">
        <v-card-text>
        <v-text-field :rules="[ rules.required, rules.count, rules.type_number ]" type="tell" v-model="code" placeholder="کد احراز هویت" required/>
        <div class="d-flex justify-space-between align-center">
        <span v-if="time != 0" class="medium-font darkDisable--text mr-2">ارسال مجدد </span>
        <span @click="send_again" v-if="time == 0" class="medium-font info--text mr-2 cursor-pointer">ارسال مجدد </span>
        <span class="medium-font ml-2" v-if="time != 0" v-text="time"></span>
        </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="check" class="onPrimaryActions--text primaryActions button">ورود</v-btn>
        </v-card-actions>
        </v-card>
      </v-form>
    </v-row>
  </div>
</template>
<script>
import navbar from '@/components/navbar/actionBar'
import submenu from '@/components/navbar/subMenu'
import error from '@/components/alerts/error.alert'
import { alertMessage } from '@/assets/messageAlert'
import { toEnglishDigits } from '@/models/changeType'
import { confirm, resend_code } from './models/auth'
export default {
  components: {
    // navbar,
    // submenu,
    error
  },
  data: () => ({
    logo: '',
    time: 0,
      code: '',
    rules: {
      required: null,
      count: value => value.length <= 20 || alertMessage.error['MaxCharacters'],
      type_number: value => {
        const mobile = toEnglishDigits(value)
        const pattern = /^[0-9]+$/
        return pattern.test(mobile) || alertMessage.error['type_number']
      },
    }
  }),
  created () {
    this.logo = localStorage.getItem('logo_site')
    const register = localStorage.getItem('registerd')
    if (register) {
        this.time = 60
      setInterval(() => {
      if (this.time != 0) {
        this.time --
      }
    }, 1000) 
    }
  },
  methods: {
    check () {
      this.rules =
      value => !!value || alertMessage.error['require']
    },
    send_again () {
        this.time = 60
        setInterval(() => {
        if (this.time != 0) {
          this.time --
        }
      }, 1000)
        resend_code()
      },
    confirm () {
      const validation = this.$refs.confirm.validate()
      if (validation == true) {
        confirm(toEnglishDigits(this.code))
      }
    }
  }
}
</script>