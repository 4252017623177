<template>
  <div>
    <v-container fluid>
      <navbar/>
      <submenu @open_cat='open_cat'/>
      <div class="section">
        <blocks/>
    <!-- <v-row>
      <v-col cols="1" class="pa-3 d-flex justify-center">
      <side class="fixed"/>
      </v-col>

      <v-col class="pa-2">

      <v-row class="ml-2">
        <v-col  class="pa-2">
          <product/>
        </v-col>
        <v-col class="pa-2">
          <product/>
        </v-col>
        <v-col class="pa-2">
          <product/>
        </v-col>
        <v-col class="pa-2">
          <product/>
        </v-col>
      </v-row>

      <v-row class="ml-2">
        <v-col class="pa-2">
          <product/>
        </v-col>
        <v-col class="pa-2">
          <product/>
        </v-col>
        <v-col class="pa-2">
          <product/>
        </v-col>
        <v-col class="pa-2">
          <product/>
        </v-col>
      </v-row>

      </v-col>
      </v-row> -->
      </div>
    </v-container>
    <drawer :openDrawer="drawer"/>
  </div>
</template>
<style lang="scss" scoped>
.fixed{
  position: fixed;
  top: auto;
  bottom: 200px;
}
</style>
<script>
import side from '@/components/cards/subordinate/stickySideNav'
import navbar from '@/components/navbar/actionBar'
import product from '@/components/cards/product/productCard'
import submenu from '@/components/navbar/subMenu'
import blocks from '@/components/tabMenu/subordinateBlock'
import drawer from '@/components/drawers/ordinateDrawer'
export default {
  components: {
    // side,
    navbar,
    // product,
    submenu,
    blocks,
    drawer
  },
  data: () => ({
    drawer: false,
  }),
  methods:{
    open_cat (open) {
      this.drawer = open
    }
  }
}
</script>