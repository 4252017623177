<template>
<div>
  <v-card class="card flat-card" color="surface">
    <v-tabs v-model="tab">
      <v-tab>{{$t('product.specifications')}}</v-tab>
      <v-tab>{{$t('product.introduction')}}</v-tab>
      <v-tab>{{$t('product.comments')}}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-container>
        <v-tab-item >
        <description :product="product"/>
        <div v-if="product.evaluations.length < 1" class="d-flex flex-column align-center pa-3">
          <v-img class="my-3" width="80" src="@/assets/icons/description.svg"></v-img>
          <span class="medium-font onSurface--text">{{$t('product.noCase')}}</span>
        </div>
      </v-tab-item>
      <v-tab-item>
        <introduction v-if="product.review_description" :product='product'/>
        <div v-if="!product.review_description" class="d-flex flex-column align-center pa-3">
          <v-img class="my-3" width="80" src="@/assets/icons/description.svg"></v-img>
          <span class="medium-font onSurface--text">{{$t('product.noCase')}}</span>
        </div>
      </v-tab-item>
      <v-tab-item>
        <v-row>
          <addComment @add_comment="add_comment" :productID="product.id"/>
        </v-row>
        <v-row v-for="comment in comments" :key="comment.Comment_ID_PK" class="mx-4 my-3 pb-2">
          <commentCard :comment='comment'/>
        </v-row>
      </v-tab-item>
      </v-container>
    </v-tabs-items>
  </v-card>
</div>
</template>
<script>
import description from '../cards/productDetail/descriptionCard'
import introduction from '../cards/productDetail/introductionCard'
import addComment from '../cards/productDetail/addComment'
import commentCard from '../cards/productDetail/commentCard'
import { mapState } from 'vuex'
export default {
  components: {
    description,
    introduction,
    addComment,
    commentCard
  },
  data: () => ({
    tab: null,
    modal: false
  }),
    computed: {
      ...mapState({
        product:state => state.basket.product_detail,
        similar: state => state.basket.similarProducts,
        comments: state => state.productDetail.comments_product
      }),
    modal: false
    },
    methods:{
      add_comment (added) {
        this.modal = added
        scrollTo(0,0)
      }
    }
  }
</script>