<template>
  <div>
    <v-btn
      :disabled="slider.onhand == 0"
      type="button" :class="slider.onhand == 0 ? 'disableColor' : 'onSecondaryActions--text secondaryActions'"
      class="rounded-btn btn-block button"
      @click="open_basket">

      <v-icon v-if="slider.onhand != 0" size="19" class="onSecondaryActions--text">mdi-plus</v-icon>
      <span v-if="slider.onhand != 0" class="onSecondaryActions--text">سبد خرید</span>
      <span v-if="slider.onhand == 0">ناموجود</span>
    </v-btn>
  </div>
</template>
<script>
export default {
	props: ['slider'],
  methods:{
    open_basket () {
      if (this.$props.slider.onhand != 0) {
        this.$emit("open", true)
      }
    }
  }
}
</script>