<template>
  <div class="overlay" @click.self="openDrawer = !openDrawer" v-if="openDrawer">
  <div class="drawer-container surface">
    <v-list v-if="!drawer" dense class="surface">
      <div v-for="(categorie, index) in categories" :key="categorie.id">
      <v-list-item v-if="index < 8 && !max_show" link  @click="show_category(categorie)">
        <v-list-item-content  class="onSurface--text">
          <v-list-item-title v-text="categorie.title"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="max_show" link  @click="show_category(categorie)">
        <v-list-item-content  class="onSurface--text">
          <v-list-item-title v-text="categorie.title"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      </div>
    </v-list>
    <v-btn class="primaryText--text mr-8 button" text @click="max_show = !max_show" v-if="!max_show && categories.length > 8 && !drawer">
    <v-icon size="18" class="primaryText--text">mdi-plus</v-icon>
    {{$t('search.seeAll')}}
    </v-btn>
    <v-btn class="primaryText--text mr-8 button" text @click="max_show = !max_show" v-if="max_show && !drawer">
    <v-icon size="18" class="primaryText--text">mdi-minus</v-icon>
    <span class="medium-font primaryText--text">{{$t('search.seeLess')}}</span>
    </v-btn>
    <subOrdinate :title='category' @close='close' v-if="drawer"/>
  </div>
 </div>
</template>
<script>
import subOrdinate from '../drawers/subOrdinateDrawer'
import { categories_list, show_category_list } from '../../views/shop/models/categoreis'
import { mapState } from 'vuex'
export default {
  props: ['openDrawer'],
  components: {
    subOrdinate
  },
  data: () => ({
    show: false,
    drawer: false,
    category: '',
    max_show: false
  }),
    computed: {
      ...mapState({
          categories: state => state.categories.categories,
          loading: state => state.search.load_page,
      })
    },
    created () {
      localStorage.removeItem('filter_search')
        categories_list('')
    },
    methods: {
      show_category (category) {
        show_category_list(category.id)
        this.category = category.title
        this.drawer = true
      },
      close (close) {
        this.drawer = close
      }
    }
}
</script>