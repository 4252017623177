<template>
  <v-card class="d-flex flex-column align-center pa-2 card flat-card" color="surface">
    <v-row class="my-3 mx-2">
      <div v-html="product.review_description">
      </div>
    </v-row>
  </v-card>
</template>
<script>
export default {
  props: ['product']
}
</script>