<template>
<div>
  <v-container class="footer-services-grid" fluid>
    <div class="d-flex flex-column align-center pa-3">
      <v-avatar tile size="80">
        <v-img src="@/assets/icons/delivery-truck.svg"/>
      </v-avatar>
      <span class="font mt-2">ارسال رایگان</span>
      <span class="small-font mt-1 mb-0" style="">برای سفارش های بیشتر از 200,000 تومان</span>
    </div>
    <v-divider vertical/>
    <div class="d-flex flex-column align-center pa-3">
      <v-avatar tile size="80">
        <v-img src="@/assets/icons/call-center.svg"/>
      </v-avatar>
      <span class="font mt-2">مرکز تماس</span>
      <span class="small-font mt-1">10:00 الی 16:00</span>
    </div>
    <v-divider vertical/>
    <div class="d-flex flex-column align-center pa-3">
      <v-avatar tile size="80">
        <v-img src="@/assets/icons/delivery-gurantee.svg"/>
      </v-avatar>
      <span class="font mt-2">تضمین کیفیت کالا</span>
      <span class="small-font mt-1">امکان بازگشت کالا تا 7 روز</span>
    </div>
  </v-container>
  <div class="footer footerBg">
    <v-container>
      <v-row align="center" class="footer-container">
        <v-col cols="5">
          <div class="logo-container">
            <img :src="logo" width="100%"/>
          </div>
        </v-col>
        <v-col sm="3" md="3" lg="3">
          <v-row align="center">
            <v-col class="links">
              <h3 class="onFooter--text">اطلاعات تماس</h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex align-center links pt-0">
              <span class="onFooter--text">شماره پشتیبانی :</span>
              <span class="onFooter--text mx-2" v-text="phone"></span>
            </v-col>
          </v-row>
        </v-col>
        <!-- <v-col sm="3" md="3" lg="3">
          <v-row align="center">
            <v-col class="links">
              <h3 class="onFooter--text">دسترسی ها :</h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex align-center links pt-0">
              <router-link to="https://www.tebyan.net/" target="_blank">
                <span class="onFooter--text">سایت تبیان</span>
              </router-link>
            </v-col>
          </v-row>
        </v-col> -->
        <!-- <v-col cols="12" sm="3" md="3" lg="2" class="connect-container">
        <v-row>
          <v-col>
            <h3 class="darkDisable--text">{{ $t('footer.followUs') }}</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0">
            <v-icon class="darkDisable--text" size="30">mdi-youtube</v-icon>
            <v-icon class="darkDisable--text mx-1" size="25">mdi-linkedin</v-icon>
            <v-icon class="darkDisable--text mx-1" size="25">mdi-twitter</v-icon>
            <v-icon class="darkDisable--text mx-1">mdi-instagram</v-icon>
          </v-col>
        </v-row>
        </v-col> -->
      </v-row>
      <v-row class=" d-flex justify-center">
        <v-col>
          <p class="text-center onFooter--text numbers-font mb-0">2021 © کلیه حقوق مادی و معنوی این سایت متعلق به {{title}}  می‌باشد</p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</div>
</template>
<script>
export default {
  data: () =>({
    title: '',
    logo: '',
    phone: ''
  }),
  created () {
    this.logo = localStorage.getItem('logo_site2')
    const config = JSON.parse(localStorage.getItem("config"))
    this.title = config.title
    this.phone = config.phone
  }
}
</script>
