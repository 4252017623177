<template>
  <div>
    <v-card class="mt-3 card flat-card" color="surface">
      <v-row class="pa-2 mx-2 d-flex align-center">
        <span class="base-font onSurface--text">{{$t('basket.deliveryAddress')}}</span>
        <v-spacer></v-spacer>
        <div class="mx-4" style="cursor: pointer" @click="modal = !modal">
          <v-icon class="primaryText--text" size="19">mdi-plus</v-icon>
          <span class="medium-font primaryText--text cursor-pointer">{{$t('basket.addNew')}}</span>
        </div>
      </v-row>
      <v-divider class="my-4 mx-5"></v-divider>
    <v-radio-group @change="receiveraddress()" v-model="radioGroup">
    <div v-for="receiver in receivers" :key="receiver.id" class="mx-2 mb-2 hover card ">
      <div>
      <v-row class="pa-2 mx-2">
        <div class="pt-2">
        <v-radio :value="receiver.id"  :label="receiver.title"></v-radio>
        </div>
      </v-row>
      <v-row class="pa-2 mx-2 d-flex align-center">
        <v-icon size="20" class="onSurface--text">mdi-map-marker</v-icon>
        <span class="medium-font onSurface--text mx-1" v-text="receiver.address"></span>
      </v-row>
      <v-row class="pa-2 mx-2 d-flex align-center">
        <v-icon size="20" class="onSurface--text">mdi-account</v-icon>
        <span class="medium-font mx-1 onSurface--text" v-text="receiver.receiver"></span>
      </v-row>
      <v-row class="pa-2 mx-2 d-flex align-center">
        <v-icon size="20" class="onSurface--text">mdi-cellphone</v-icon>
        <span class="medium-font mx-1 numbers-font onSurface--text" v-text="receiver.mobile"></span>
      </v-row>
      <v-row class="pa-2 mx-2 mb-2 d-flex align-center">
        <div v-if="receiver.phone">
        <v-icon size="20" class="onSurface--text">mdi-phone</v-icon>
        <span class="medium-font mx-1 numbers-font onSurface--text" v-text="receiver.phone"></span>
        </div>
      </v-row>
    </div>
    </div>
    <v-divider class="my-2 mx-3"></v-divider>
    </v-radio-group>
    </v-card>
    <div class="modal-overlay" @click.self="modal = !modal" v-if="modal">
      <addLoc @back = 'back' />
    </div>
  </div>
</template>
<style scoped>
.hover:hover {
  background-color:#f6f7f8;
  cursor: pointer;
}
</style>
<script>
import { mapState } from 'vuex'
import Store from '@/store/index'
import router from '@/router'
import addLoc from '@/components/forms/addAddress'
export default {
  components: {
    addLoc
  },
  data: () => ({
    radioGroup: true,
    modal: false
  }),
    computed: {
    ...mapState({
      receivers: state => state.address.UserAddress,
      addressBasket: state => state.basket.addressBasket,
    })
  },
  watch: {
    receivers: {
      deep: true,
      handler: function () {
        if (this.receivers.length == 0) {
          this.modal = true
          return
        }
        if (this.addressBasket) {
          this.radioGroup = this.addressBasket
          Store.dispatch('addressBasket', this.radioGroup)
          return
        }
        this.radioGroup = this.receivers[0].id
        Store.dispatch('addressBasket', this.radioGroup)
      }
    }
  },
  methods: {
    receiveraddress() {
      Store.dispatch('addressBasket', this.radioGroup)
    },
    back(close) {
      this.modal = close
    }
  }
}
</script>