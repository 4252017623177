import axios from "axios"
import Store from '@/store/index'
import{ changeTypePrice, changeTypecount, calOffer } from '@/models/changeType'
import router from "@/router"
import store from "@/store/index"
const token = localStorage.getItem('access_token')

export function add_comment(comment: {}) {
    store.dispatch('load_page', true)
    axios.post('/api/Store/Products/comment',comment,{
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }).then(Response => {
        store.dispatch('load_page', false)
        if (Response.data.Result.Result == 1) {
            Store.dispatch("alertSuccess",{
                show: true,
                type: 'success',
                typeMessage:'comment',
                color:"success"
              })
              setTimeout(() => {
                Store.dispatch("alertSuccess",{
                  show: false,
                  type: 'success',
                  typeMessage:'comment',
                  color:"success"
                })
								router.back()
              }, 3000);
        } else {
					Store.dispatch("alertSuccess",{
						show: true,
						type: 'error',
						typeMessage:'network',
						color:"error"
					})
					setTimeout(() => {
						Store.dispatch("alertSuccess",{
							show: false,
							type: 'success',
							typeMessage:'network',
							color:"success"
						})
					}, 3000);
        }
    })
}

export function comments_product(productID: string) {
    axios.post('/api/Store/Products/comments',{
      product_id: productID
    }).then(Response => {
        store.dispatch('comments_product', Response.data.Result)
    }) 
}