import { render, staticRenderFns } from "./discountLabel.vue?vue&type=template&id=3d8dc563&scoped=true&"
import script from "./discountLabel.vue?vue&type=script&lang=js&"
export * from "./discountLabel.vue?vue&type=script&lang=js&"
import style0 from "./discountLabel.vue?vue&type=style&index=0&id=3d8dc563&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d8dc563",
  null
  
)

export default component.exports