<template>
  <div>
    <navbar/>
    <submenu @open_cat='open_cat'/>
    <div class="fixed-alert-adminPanel">
      <alert/>
    </div>
    <div class="section">
    <adminpanel class="display-none"/>
    <v-main class="main-container">
      <v-container class="pt-0">
        <v-row v-if="receivers.length < 1 && !loading">
          <v-col class="d-flex flex-column justify-center align-center">
            <v-avatar tile size="200">
              <v-img src="@/assets/icons/locations.svg"/>
            </v-avatar>
            <span class="lg-font mt-2">{{$t('myLocations.noLoc')}}</span>
          </v-col>
        </v-row>
        <div>
          <v-row v-if="receivers.length < 1 && loading" class="mx-2">
            <v-col cols="12" class="pt-0">
              <addressLoader/>
            </v-col>
          </v-row>
          <v-row class="mx-2 my-2">
            <v-col v-for="receiver in receivers" :key="receiver.id" cols="12">
              <location :receiver='receiver' />
            </v-col>
          </v-row>
            <div @click="modal = !modal" class="add-btn cursor-pointer">
              <v-btn large icon class="onPrimaryActions--text primaryActions ">
                <v-icon class="onPrimaryActions--text">mdi-map-marker-plus</v-icon>
              </v-btn>
            </div>
        </div>
      </v-container>
    </v-main>
    </div>
    <div class="modal-overlay" @click.self="modal = !modal" v-if="modal">
      <addLoc @back = 'back' />
    </div>
    <drawer :openDrawer="drawer"/>
  </div>
</template>
<style lang="scss" scoped>
.section{
  padding-top: 30px;
}
</style>
<script>
import navbar from '@/components/navbar/actionBar'
import submenu from '@/components/navbar/subMenu'
import adminpanel from '@/components/adminPanel/drawer'
import location from '@/components/adminPanel/locationsCard'
import addLoc from '@/components/forms/addAddress'
import drawer from '@/components/drawers/ordinateDrawer'
import addressLoader from '@/components/skeletonLoader/addressCardsLoader'
import { getAddress } from '@/views/adminPanel/models/address'
import { mapState } from 'vuex'
export default {
  components: {
    navbar,
    submenu,
    adminpanel,
    location,
    addLoc,
    drawer,
    addressLoader
  },
  data: () => ({
    modal: false,
    drawer: false,
  }),
  computed: {
    ...mapState({
      receivers: state => state.address.UserAddress,
      loading: state => state.loading_page
    })
  },
  created () {
    getAddress()
  },
  methods:{
    open_cat (open) {
      this.drawer = open
    },
    back (back) {
      this.modal = back
    }
  }
}
</script>