<template>
<div class="w-100">
  <div class="fixed-alert-adminPanel">
    <alert/>
  </div>
  <v-card class="card flat-card pa-3" color="surface">
    <v-form @submit.prevent="set_edit()" ref="editProfile">
      <v-row class="mx-1 my-2">
        <span class="lg-font primaryText--text">{{$t('adminPanel.editProfile')}}</span>
      </v-row>
      <v-row class="my-2" justify="center">
        <v-avatar size="150">
          <v-img :src="user_profile.image"></v-img>
        </v-avatar>
      </v-row>
      <v-row class="mx-2">
        <v-col cols="12" md="6">
        <v-file-input
          @change="getImage"
          small-chips
          :label="$t('adminPanel.changePhoto')"
        ></v-file-input>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            :label="$t('auth.nationalCode')"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mx-2">
        <v-col cols="12" md="6">
          <v-text-field
          maxlength="21"
          :rules="[ rules.required, rules.count ]"
          v-model="user_profile.nick_name"
          :label="$t('auth.username')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
          disabled
          v-model="user_profile.mobile"
          :label="$t('auth.mobile')"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mx-2">
        <v-col cols="12" md="6">
          <v-text-field
          maxlength="21"
          :rules="[ rules.required, rules.count ]"
          v-model="user_profile.first_name"
          :label="$t('auth.name')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
          maxlength="21"
          :rules="[ rules.required, rules.count ]"
          v-model="user_profile.last_name"
          :label="$t('auth.lastname')"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="check" :disabled="submit_btn" type="submit" class="button onPrimaryActions--text primaryActions">{{$t('auth.save')}}</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
    <cropper v-if="src" :url= 'src' @close= 'close' @image = 'image'/>
</div>
</template>
<script>
import { mapState } from 'vuex'
import {alertMessage} from '@/assets/messageAlert'
import { toEnglishDigits } from '@/models/changeType'
import { editProfile } from '@/views/adminPanel/models/user'
import cropper from '../cropper/cropper'
import alert from '@/components/alerts/alert'
export default {
  components: {
    cropper,
    alert
  },
  data: () => ({
    dialog: false,
    password: false,
    src: '',
    user_profile: {
      nick_name: '',
      first_name: '',
      last_name: '',
      mobile: '',
      user_id: '',
      image:''
    },
    rules: {
      required: null,
      count: value => value.length <= 20 || alertMessage.error['MaxCharacters'],
      phone: value => {
        const mobile = toEnglishDigits(value)
        return mobile.slice(0,2) == '09' || alertMessage.error['typePhone']
      }
    }
  }),
  computed: {
    ...mapState({
      user: state => state.user.user,
      submit_btn: state => state.loadingSubmits
    })
  },
  watch: {
    user: {
      handler: function () {
        this.user_profile = this.user
      }
    }
  },
  methods: {
    check () {
      this.rules = {
        required: value => !!value || alertMessage.error['Required']
      }
    },
    set_edit () {
      const validate = this.$refs.editProfile.validate()
      if ( validate == true) {
        editProfile(this.user_profile)
      }
    },
    getImage(event) {
        const reader = new FileReader()
        reader.readAsDataURL(event)
        reader.onload = event => {
          this.src = event.target.result
        }
        document.getElementById('my-file').value = null
    },
      image (cropper) {
        this.user_profile.image = cropper.image
        this.src = null
        
      },
      close (close) {
        if (close == false) {
          this.src = null
        }
      }
  }
}
</script>