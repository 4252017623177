<template>
  <div class="product-slider-container">
    <div class="mx-1 mt-5">
      <v-card width="280" height="465" color="surface" class="mx-2 py-2 card flat-card product-category-card">
        <v-row class="py-3 px-3 mx-1">
          <span class="primaryText--text mt-1" v-text="block.title"></span>
        </v-row>
        <v-divider class="mt-4"></v-divider>
        <div class="px-2 pt-4 mx-auto my-auto" style="width:263px; height: 390px;">
          <img :src="block.banner" style="width:100%; height: 100%"/>
        </div>
      </v-card>
    </div>
    <div class="mobile-view">
      <span class="primaryText--text mt-1" v-text="block.title"></span>
    </div>
    <v-card class="card flat-card mt-5 pa-3" color="surface">
      <div v-for="lists in blockList" :key='lists.blockID'>
      <v-row  v-if="lists.blockID == block.id" class="surface pa-2 radius-top-side" align="center" justify="space-between">
      <div class="d-flex align-center">
      <div @click="change_list(list, i)" v-for="(list, i) in lists.lists" :key='list.id' class="tab-item-container" :class="showDefult.id == list.id ? 'accent': ''">
        <span class="medium-font primaryText--text" v-text="list.title"></span>
      </div>
      </div>
      <div @click="show_more">
        <v-btn class="primaryText--text button" text>
        {{$t('search.seeAll')}}
        <v-icon size="20">{{$t('categories.arrow')}}</v-icon>
        </v-btn>
      </div>
    </v-row>
    </div>
    <v-divider class="mt-3 mx-3"></v-divider>
    <splide :options= "options" :slides = 'slides'>
      <splide-slide v-for="slide  in slides" :key="slide.id">
        <product :product = 'slide'/>
      </splide-slide>
    </splide>
    <splide v-if="slides.length < 1" :options="option" :slides = 'slides'>
      <splide-slide>
        <v-skeleton-loader
          v-bind="attrs"
          type="image, article, actions" max-width="250" height="370"
        ></v-skeleton-loader>
      </splide-slide>
      <splide-slide>
        <v-skeleton-loader
          v-bind="attrs"
          type="image, article, actions" max-width="250" height="370"
        ></v-skeleton-loader>
      </splide-slide>
      <splide-slide>
        <v-skeleton-loader
          v-bind="attrs"
          type="image, article, actions" max-width="250" height="370"
        ></v-skeleton-loader>
      </splide-slide>
      <splide-slide>
        <v-skeleton-loader
          v-bind="attrs"
          type="image, article, actions" max-width="250" height="370"
        ></v-skeleton-loader>
      </splide-slide>
    </splide>
    </v-card>
  </div>
</template>
<style lang="scss" scoped>
.v-menu__content.theme--light.menuable__content__active{
  z-index: 0 !important;
}
</style>
<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import product from '../cards/product/sliderCard'
import { mapState } from 'vuex'
import { blockList, viewblock } from '@/views/shop/models/home'
import { viewbasket_products } from '@/views/shop/models/basket'
import store from '@/store';
import { search_product_tag } from '@/views/shop/models/search';
export default {
    components: {
    Splide,
    SplideSlide,
    product,
  },
  props: ['block'],
  computed: {
    ...mapState({
      blockList: function (state) {
        const lists = state.home.blockList
        return lists
      },
      products: state => state.home.blockProduct,
      products_nohand: state => state.home.blockProduct_nohand,
      selected_list: state => state.home.selected_list,
      search_fild: state => state.search.searchFild
    }),
    showList () {
      let items = []
      for (const block of this.blockList) {
        if (block.blockID == this.$props.block.id) {
          items = block.lists
        }
      }
      return items
    },
    showDefult () {
        let Defult = []
      for (const block of this.blockList) {
        if (block.blockID == this.$props.block.id) {
          for (const selected of this.selected_list) {
            if (selected.block_id == this.$props.block.id) {
              Defult = block.lists[selected.index]
            }
          }
        }
      }
      return Defult
    },
    slides () {
      let products = []
      for (const product of this.products) {
        if (product.parentID == this.$props.block.id) {
            products = product.products
        }
      }
      return products
    }
  },
  data: () => ({
    active: {},
    blockID: '',
    items: [],
    benched: 0,
    options: {
      rewind : true,
      trimSpace: true,
      width: '100%',
      perPage: 4,
      arrows: true,
      drag: true,
      pagination: false,
      waitForTransition: false,
      perMove: 1,
      gap: '0.5rem',
      start: 0,
      focus: 'center',
      direction: 'rtl'
    },
		option: {
      rewind : true,
      trimSpace: true,
      width: '100%',
      perPage: 4,
      arrows: false,
      drag: true,
      pagination: false,
      waitForTransition: false,
      type   : 'loop',
      perMove: 1,
      gap: '0.5rem',
      start: 0,
      focus: 'center',
      direction: 'rtl'
    },
  }),
  mounted () {
    if (this.products.length < 1) {
      blockList(this.$props.block.id)
    }
  },
  methods: {
    change_list (list_id, index) {
      store.dispatch('blockProduct', {products: [], parentID: this.$props.block.id})
      store.dispatch('selected_list', {index: index, block_id: this.$props.block.id})
      viewblock(list_id.id,this.$props.block.id)
      const basket = localStorage.getItem('basket')
      if (basket) {
        store.dispatch('basket_local', JSON.parse(basket))
      } else {
        viewbasket_products()
      }
    },
    select (select, index) {
      store.dispatch('selected_list', {index: index, block_id: this.$props.block.id})
      viewblock(select.id,this.$props.block.id)
      viewbasket_products()
    },
    show_more () {
      let filters = localStorage.getItem('filter_search')
        if (filters) {
          filters = JSON.parse(filters)
          filters.page = 1
        } else {
          filters = {}
          filters.page = 1
        }
      switch (this.showDefult.filter_type) {
        case '1':
          filters.block_id = this.showDefult.id
          filters.title = {title: this.showDefult.title, value: 'block_id'}
          localStorage.setItem('filter_search', JSON.stringify(filters))
        setTimeout(() => {
          search_product_tag(filters)
        }, 500);
          break;
          case '2':
        filters.group = this.showDefult.filter_search
        filters.title = {title: this.showDefult.title, value: 'group'}
        localStorage.setItem('filter_search', JSON.stringify(filters))
        setTimeout(() => {
          search_product_tag(filters)
        }, 500);
          break;
           case '3':
        filters.tags = this.showDefult.filter_search
        filters.title = {title: this.showDefult.title, value: 'tags'}
        localStorage.setItem('filter_search', JSON.stringify(filters))
        setTimeout(() => {
          search_product_tag(filters)
        }, 500);
          break;
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.grid{
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  width: 95%;
  align-items: center;
}
</style>