<template>
  <div class="search-wrapper ">
    <div class="prepend">
      <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="px-2 button onBackground--text background prepend-btn"
          v-bind="attrs"
          v-on="on" block>
          {{cate}}
          <v-icon class="onBackground--text mr-auto">mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item
        link
        v-for="(item, index) in items" :key="index" @click="select_cat(item)">
          <v-list-item-title class="py-0">{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
  <input @keyup="search_product" v-model="search_fild_input" type="search" class="search-input accent darkDisable--text" :placeholder="$t('search.search')">
    <button @click="search_product_btn" class="onBackground--text background append">
      <v-icon class="onBackground--text">mdi-magnify</v-icon>
    </button>
  </div>
</template>

<script>
import { categories_list, show_category_list } from '../../views/shop/models/categoreis'
import { search_product_tag, search_product } from '@/views/shop/models/search'
import { mapState } from 'vuex'

  export default {
    data: () => ({
      items: [{title: "همه", tag: []}],
      search_fild_input: '',
      cate: 'همه'
    }),computed: {
      ...mapState({
          categories: state => state.categories.categories,
          loading: state => state.search.load_page,
      })
    },
    watch: {
      categories: {
        handler: function () {
          for (const cate of this.categories) {
            this.items.push(cate)
          }
        }
      }
    },
    created () {
      categories_list('')
      let filters = localStorage.getItem('filter_search')
      if (filters) {
        filters = JSON.parse(filters)
        this.search_fild_input = filters.search
        if (filters.title) {
          this.cate = filters.title.title
        }
        search_product(filters)
      }
    },
    methods: {
      select_cat (item) {
        this.cate = item.title
        let filters = localStorage.getItem('filter_search')
          if (filters) {
            filters = JSON.parse(filters)
            let tages = ''
            if (item.tag.length > 1 && item.title != "همه") {
              for (const key of item.tag) {
                tages = key + ',' + tages
              }
            } else if (item.tag.length == 1 && item.title != "همه") {
              tages = item.tag[0]
            }
            filters.tags = tages
            filters.title = {title: item.title, value: 'tags'}
            localStorage.setItem('filter_search', JSON.stringify(filters))
          } else {
            filters = {}
            let tages = ''
            if (item.tag.length > 1) {
              for (const key of item.tag) {
                tages = key + ',' + tages
              }
            } else {
              tages = item.tag[0]
            }
            filters.tags = tages
            filters.title = {title: item.title, value: 'tags'}
            localStorage.setItem('filter_search', JSON.stringify(filters))
          }
      },
      search_product: function (e) {
        const route = this.$router.history.current.name
        if (route != 'search') {
          if (e.keyCode == 13) {
          let filters = localStorage.getItem('filter_search')
        if (filters) {
          filters = JSON.parse(filters)
          filters.search = this.search_fild_input
          localStorage.setItem('filter_search', JSON.stringify(filters))
        } else {
          filters = {}
          filters.search = this.search_fild_input
          localStorage.setItem('filter_search', JSON.stringify(filters))
        }
          search_product_tag(filters)
        }
        }else {
          if (e.keyCode == 13) {
          let filters = localStorage.getItem('filter_search')
        if (filters) {
          filters = JSON.parse(filters)
          filters.search = this.search_fild_input
          localStorage.setItem('filter_search', JSON.stringify(filters))
        } else {
          filters = {}
          filters.search = this.search_fild_input
          localStorage.setItem('filter_search', JSON.stringify(filters))
        }
          search_product(filters)
        }
        }
      },
      search_product_btn () {
        const route = this.$router.history.current.name
        let filters = localStorage.getItem('filter_search')
        if (filters) {
          filters = JSON.parse(filters)
          filters.search = this.search_fild_input
          localStorage.setItem('filter_search', JSON.stringify(filters))
        } else {
          filters = {}
          filters.search = this.search_fild_input
          localStorage.setItem('filter_search', JSON.stringify(filters))
        }
        if (route != 'search') {
          search_product_tag(filters)
        } else {
          search_product(filters)
        }
      }
    }
  }
</script>