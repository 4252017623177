<template>
  <div class="w-100">
    <v-card class="pa-3 card flat-card my-3 " color="background">
      <v-row align="center">
        <v-col cols="12" sm="7" class="d-flex">
          <!-- <v-avatar size="40">
            <v-img src="@/assets/images/register.jpg"></v-img>
          </v-avatar> -->
          <span class="mx-1"></span>
          <div class="d-flex flex-column">
            <span class="font font-weight-bold onSurface--text" v-text="comment.Name">@nickname</span>
            <span class="medium-font darkDisable--text" v-text="comment.Insert_Time"></span>
          </div>
        </v-col>
        <v-col dir="ltr">
          <rating :rating='comment.Rate' :config = 'config' class="ml-2"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-0">
        <p class="font" v-text="comment.Comment_Text"></p>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import rating from '@/components/rate/rating'
export default {
  props:['comment'],
  components: {
    rating
  },
  data: () => ({
    like: false,
    config: {
      width: 17,
      rounded:true,
      showRating:false,
      readonly:true
    }
  }),
  methods: {
    openBox() {
      this.$emit('comment', true)
    }
  }
}
</script>