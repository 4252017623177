<template>
  <div class="wrapper d-flex justify-center">
    <splide :options="options" :slides = 'slides'>
      <splide-slide v-for="slide  in slides" :key="slide.id">
        <img @click="search_banner(slide.tag_id)" width="100" height="100" :src="slide.image"/>
      </splide-slide>
    </splide>
  </div>
</template>
<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import { galleries } from '@/views/shop/models/home'
import { mapState } from 'vuex';
import store from '@/store';
import { search_product_tag } from '@/views/shop/models/search';
export default {
   components: {
    Splide,
    SplideSlide,
  },
   props: ['imageWidth', 'imageHeight'],
   data: () => ({
      options: {
        rewind : true,
        trimSpace: true,
        width: '100%',
        perPage: 8.5,
        arrows: false,
        pagination: false,
        autoplay: true,
        type   : 'loop',
        gap: '0.5rem',
        start: 0,
        focus: 'center',
        direction: 'rtl',
        pauseOnHover: true
      }
   }),
   created () {
  let config = localStorage.getItem('config')
  if (config) {
    config = JSON.parse(config)
  }
  galleries(config.mobile_slider_gallery,'banner')
  galleries(config.brands_gallery,'brands')
},
computed: {
      ...mapState({
        slides: state => state.home.brands,
        search_fild: state => state.search.searchFild
      })
    },
    mounted () {
      for (const image of this.slides) {
      caches.open('banner').then(cache => {
      cache.add(image.image)
     })
    }
    },
   methods: {
     search_banner (tag_id) {
       console.log(tag_id)
       store.dispatch('search_fild', {fild: tag_id, type: 'tags'})
        setTimeout(() => {
          search_product_tag(this.search_fild)
        }, 500);
     }
   }
}
</script>