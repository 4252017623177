<template>
  <div class="admin-drawer-container">
    <v-navigation-drawer
      v-model="drawer"
      :expand-on-hover="expandOnHover"
      :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
      :mini-variant.sync="mini"
      permanent
      right
      :src="barImage"
    >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>
      <v-list dense >
        <v-list-item-group color="primary">
        <v-list-item link @click="change_route('userProfile')" :class="current_tab == 'userProfile' ? 'v-item--active v-list-item--active':''">
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="web-view">
            <v-list-item-title>{{$t('adminPanel.myProfile')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link @click="change_route('myBills')" :class="current_tab=='myBills' ? 'v-item--active v-list-item--active':''">
          <v-list-item-icon>
            <v-icon>mdi-cash-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="web-view">
            <v-list-item-title>{{$t('adminPanel.myBills')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link @click="change_route('myLocations')" :class="current_tab == 'myLocations' ? 'v-item--active v-list-item--active':''">
          <v-list-item-icon>
            <v-icon>mdi-map-marker-radius</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="web-view">
            <v-list-item-title>{{$t('adminPanel.myAddresses')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link @click="change_route('myOrders')" :class="current_tab=='myOrders' ? 'v-item--active v-list-item--active':''">
          <v-list-item-icon>
            <v-icon>mdi-clipboard-text</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="web-view">
            <v-list-item-title>{{$t('adminPanel.myOrders')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- <v-list-item link @click="myBookmarks()">
          <v-list-item-icon>
            <v-icon>mdi-bookmark</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{$t('adminPanel.bookmarks')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <v-list-item link @click="change_route('changePass')" :class="current_tab == 'changePass' ? 'v-item--active v-list-item--active':''">
          <v-list-item-icon>
            <v-icon>mdi-lock</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="web-view">
            <v-list-item-title>{{$t('adminPanel.changePass')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click="change_route('inviteFriends')" :class="current_tab == 'inviteFriends' ? 'v-item--active v-list-item--active':''">
          <v-list-item-icon>
            <v-icon>mdi-account-multiple-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="web-view">
            <v-list-item-title>{{$t('adminPanel.inviteFriend')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import store from '@/store'
  export default {
    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
      currentTab: {
        type: String,
      }
    },
    data: () => ({
      mini: false,
      is_active: ''
    }),
    computed: {
      ...mapState({
          barImage: state => state.barImage,
          barColor: state => state.barColor,
          user: state => state.user.user,
          menu: state => state.menus,
          current_tab: state => state.current_tab
      }),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
    },
    mounted () {
      this.is_active = this.$props.currentTab
    },
    methods: {
      change_route(route) {
        this.$router.push(`/panel/${route}`)
        store.dispatch("current_tab", route )
      },
      route(route) {
        this.$router.push(`/panel/${route}`)
      }
    }
  }
</script>