<template>
  <v-card class="pa-2 card flat-card" color="surface" width="240" height="400">
    <v-row class="pt-3">
      <v-col class="pa-0 d-flex justify-center">
        <div @click="productDetail(result.id)" class="cursor-pointer" style="width: 190px; height: 210px">
          <img :src="result.image" style="width: 100%; height:100%"/>
        </div>
      </v-col>
    </v-row>
    <v-row class="pa-2">
      <v-col class="py-0">
        <div @click="productDetail(result.id)" class="title-container cursor-pointer">
          <span class="medium-font" v-text="result.title"></span>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="result.Is_Bulk == 1" class="mx-3">
      <v-col cols="7" class="pa-0">
        <span class="font onSurface--text">{{$t('product.priceKg')}} : </span>
      </v-col>
      <v-col cols="5" class="pa-0">
        <span class="font numbers-font onSurface--text" v-text="result.priceFormat"></span>
      </v-col>
      </v-row>
      <v-row class="mx-3">
        <v-col cols="7" class="pa-0">
          <span class="medium-font onSurface--text">{{$t('product.packagePrice')}} : </span>
        </v-col>
        <v-col cols="5" class="pa-0">
          <span class="primaryText--text medium-font numbers-font" v-if="result.Is_Bulk != 1" v-text="result.priceFormat"></span>
          <span class="primaryText--text medium-font numbers-font" v-if="result.Is_Bulk == 1" v-text="result.price_format_pack"></span>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="7" class="pa-0"></v-col>
        <v-col cols="5" class="pa-0">
          <span class="medium-font ml-2 numbers-font error--text text-decoration-line-through" v-if="result.price_gross != result.price && result.price_gross!= 0" v-text="result.price_grossFormat"></span>
          <!-- <span class="medium-font ml-3" v-if="result.onhand == 0" v-text="'ناموجود'"></span> -->
        </v-col>
      </v-row>
      <v-row class="mx-3 mb-3 ">
        <div class="product-card-button">
        <div v-if="!add_basket" @click="add_basket = !add_basket">
          <btn :product= 'basket_product'/>
        </div>
        <div class="product-card-button">
          <quantityBtn color="secondaryActions onSecondaryActions--text" block="true" v-if="add_basket && result.Is_Bulk == 1" :product= 'basket_product' />
        </div>
        <plus v-if="add_basket && result.Is_Bulk != 1" :product= 'basket_product'/>
      </div>
      </v-row>
  </v-card>
</template>
<script>
import rate from '@/components/rate/rating'
import quantityBtn from '../../buttons/quantityBtn'
import plus from '../../buttons/plusNegativeBtn'
import btn from '../../buttons/productCardBtn'
import { mapState } from 'vuex'
export default {
  props: ['result'],
  components: {
    //   rate
    quantityBtn,
    plus,
    btn
  },
  data: () => ({
    add_basket: false,
    basket_product: {},
    config: {
      width: 20,
      increment:0.5,
      rounded:true,
      showRating:false,
      readonly:true,
    }
  }),
  computed: {
    ...mapState({
      basket: state => state.basket.basket,
    })
  },
  mounted () {
    for (const item of this.basket) {
      if (item.id == this.$props.result.id) {
        this.basket_product = item
        this.add_basket = true
        return
      }
    }
    this.basket_product = this.$props.result

    caches.open('images').then(cache => {
      cache.add(this.$props.result.image)
    })
    
  },
  methods: {
    show_product () {
      this.$router.push(`/productDetail/${this.$props.result.id}`)
    },
    add_basket_product () {
      console.log('click')
    },
    productDetail (productID) {
      this.$router.push(`/productDetail/${productID}`)
    },
  }
}
</script>