<template>
  <div>
    <component :is="currentTabComponent"/>
  </div>
</template>

<script>
import userProfile from './adminMenu/userProfile'
import myBills from './adminMenu/myBills'
import myOrders from './adminMenu/myOrders'
import changePass from './adminMenu/changePass'
import myLocations from './adminMenu/myLocations'
import inviteFriends from './adminMenu/inviteFriends'
export default {
  props:['currentTab'],
  components: {
    profile: userProfile,
    bills: myBills,
    orders: myOrders,
    pass: changePass,
    locs: myLocations,
    invite: inviteFriends
  },
  computed: {
    currentTabComponent: function() {
      if (this.$props.currentTab == 'userProfile') {
        const url = 'profile'
        return url.toLowerCase();
      }
      if (this.$props.currentTab == 'myBills') {
        const url = 'bills'
        return url.toLowerCase();
      }
      if (this.$props.currentTab == 'myOrders') {
        const url = 'orders'
        return url.toLowerCase();
      }
      if (this.$props.currentTab == 'changePass') {
        const url = 'pass'
        return url.toLowerCase();
      }
      if (this.$props.currentTab == 'myLocations') {
        const url = 'locs'
        return url.toLowerCase();
      }
      if (this.$props.currentTab == 'inviteFriends') {
        const url = 'invite'
        return url.toLowerCase();
      }
      return this.$props.currentTab.toLowerCase();
    }
  }
}
</script>