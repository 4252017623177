<template>
  <div>
    <v-row>
      <v-col cols="12" md="7" lg="7">
        <v-carousel cycle height="320" hide-delimiter-background show-arrows-on-hover>
          <v-carousel-item @click="go(banner.link)" v-for="banner in banners" :key="banner.id">
            <img class="card" :src="banner.image" style="width: 100%; height: 100%;"/>
          </v-carousel-item>
        </v-carousel>
      </v-col>
      <v-col cols="12" md="5" lg="5">
        <v-card id="product-slider" class="pa-3 card flat-card d-flex flex-column" color="surface" height="320px">
          <v-row>
            <v-col>
              <span class="primaryText--text base-font font-weight-bold">{{$t('product.specialOffer')}}</span>
              <div class="title-line mt-2 primary"></div>
            </v-col>
          </v-row>
          <splide :options= "options" :slides = 'products'>
            <splide-slide v-for="product in products" :key="product.id">
              <offerCard color="surface" :slider = "product" />
            </splide-slide>
          </splide>
          <!-- <v-slide-group v-model="model" show-arrows>
            <v-slide-item v-for="product in products" :key="product.id" style="width: 100%">
              <offerCard color="white" :slider = "product" />
            </v-slide-item>
          </v-slide-group> -->
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
// import { viewblock } from '@/views/shop/models/home'
import offerCard from '../cards/product/specialOfferCard'
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import { mapState } from 'vuex'
export default {
  components: {
    offerCard,
    Splide,
    SplideSlide,
  },
  data: () => ({
    model: null,
    options: {
      rewind : true,
      trimSpace: true,
      width: '100%',
      perPage: 1,
      arrows: true,
      drag: true,
      pagination: false,
      waitForTransition: false,
      perMove: 1,
      // gap: '0.5rem',
      start: 0,
      focus: 'center',
      direction: 'rtl'
    },
  }),
  computed: {
    ...mapState({
      products: function(state) {
        const config = JSON.parse(localStorage.getItem("config"))
        const products = state.home.blockProduct
        let offer = []
        for (const pro of products) {
          if (pro.parentID == config.special_offer_block_id) {
            offer = pro.products
          }
        }
        return offer
      },
      banners: state => state.home.banner
    })
  },
  methods:{
    go(link) {
      location.replace(link)
    }
  }
}
</script>