<template>
<div>
  <v-card class="pa-2 card flat-card mt-1" color="surface">
    <v-radio-group @change="way_payment" v-model="radioGroup">
      <v-row class="pt-3">
        <v-col cols="10" class="pa-2">
          <v-radio class="mx-2" value="1" :label="$t('payment.onlinePayment')"></v-radio>
        </v-col>
        <v-col class="pa-2" cols="2">
          <v-img class="mx-auto" width="30" src="@/assets/icons/credit-card3.svg"></v-img>
        </v-col>
      </v-row>
      <v-divider class="mt-3 mb-4"></v-divider>
      <v-row>
        <v-col cols="10" class="pa-2">
          <v-radio class="mx-2" value="2" :label="$t('payment.doorToDoor')"></v-radio>
          <div class="text-start mx-2">
            <span class="small-font error--text">{{$t('payment.note')}}</span>
          </div>
        </v-col>
        <v-col class="pa-2" cols="2">
          <v-img class="mx-auto" width="30" src="@/assets/icons/pos-terminal.svg"></v-img>
        </v-col>
      </v-row>       
    </v-radio-group>
  </v-card>
</div>
</template>
<style lang="scss" scoped>
.v-input--selection-controls{
  margin-top: 7px;
}
</style>
<script>
import store from '@/store'
  export default {
    data: () => ({
        radioGroup: 1,
    }),
    methods: {
      way_payment () {
        store.dispatch('way_pay', this.radioGroup)
      }
    }
  }
</script>