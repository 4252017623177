import Vue from 'vue'
import VueI18n, { LocaleMessages } from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages (): LocaleMessages {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages: LocaleMessages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}
let lang = localStorage.getItem('lang')
let lang_local = ''
if (lang) {
  lang_local = lang
} else{
  lang_local = 'fa'
}

// const dateTimeFormats = {
//   'en-US': {
//     short: {
//       year: 'numeric',
//       month: 'short',
//       day: 'numeric'
//     },
//     long: {
//       year: 'numeric',
//       month: 'long',
//       day: 'numeric',
//       weekday: 'long',
//       hour: 'numeric',
//       minute: 'numeric'
//     }
//   },
//   'fa': {
//     short: {
//       year: 'numeric',
//       month: 'short',
//       day: 'numeric'
//     },
//     long: {
//       year: 'numeric',
//       month: 'long',
//       day: 'numeric',
//       weekday: 'long',
//       hour: 'numeric',
//       minute: 'numeric',
//     }
//   }
// }

const numberFormats = {
  'en-US': {
    currency: {
      style: 'currency',
      currency: 'USD'
    }
  },
  'fa': {
    currency: {
      style: 'currency',
      currency: 'RIAL',
      currencyDisplay: 'symbol'
    }
  }
}

const i18n = new VueI18n({
  // dateTimeFormats,
  numberFormats
})

export default new VueI18n({
  locale: lang_local,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages()
})
