<template>
<v-form ref="set_address" @submit.prevent="saveAddress()">
  <v-card class="pa-3 card height-scroll" color="surface">
    <v-row class="my-2 mx-2">
      <span class="primaryText--text font">{{$t('basket.addNew')}}</span>
    </v-row>
    <v-row >
      <v-col cols="12" md="6" class="pt-0">
        <v-text-field
        v-model="address.title"
        :label="$t('auth.title')"
        :rules="[rules.count, rules.required, rules.just_text, rules.whitespace, rules.persian_text]"
        maxlength="21"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" class="pt-0">
        <v-text-field
          :label="$t('auth.receiver')"
          v-model="address.receiver"
          maxlength="21"
          :rules="[ rules.count, rules.required, rules.just_text, rules.whitespace, rules.persian_text ]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-combobox
          @change="getCity"
          v-model="Province"
          :rules="[rules.required, rules.whitespace]"
          :items="Provinces"
          :label="$t('auth.state')"
        ></v-combobox>
      </v-col>
      <v-col cols="12" md="6">
        <v-combobox
          v-model="city"
          :items="citys"
          :rules="[rules.required, rules.whitespace]"
          :label="$t('auth.city')"
        ></v-combobox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" class="pt-0">
        <v-combobox
          disabled
          :label="$t('auth.area')"
          :items="zones_title"
          maxlength="12"
          :rules="[ rules.post_code, rules.type_number ]"
        ></v-combobox>
      </v-col>
      <v-col cols="12" md="6" class="pt-0">
        <v-text-field
          :label="$t('auth.zipCode')"
          v-model="address.postal_code"
          maxlength="10"
          :rules="[ rules.post_code, rules.type_number,rules.required ]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0">
        <v-textarea
          maxlength="201"
          dense
          rows="1"
          :rules="[ rules.required, rules.address, rules.whitespace, rules.persian_text ]"
          v-model="address.address"
          :label="$t('auth.address')"
          counter="200"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" class="pt-0">
        <v-text-field
          v-model="address.mobile"
          maxlength="11"
          :label="$t('auth.mobile')"
          :rules="[ rules.required, rules.mobile, rules.type_number,rules.mobile_count ]"
          type="tell"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" class="pt-0">
        <v-text-field
          v-model="address.phone"
          :label="$t('auth.phone')"
          maxlength="10"
          :rules="[ rules.phone, rules.type_number ]"
          type="tell"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-card-actions class="mt-2">
      <v-spacer></v-spacer>
      <v-btn @click="back" class="button small-button" text>{{$t('auth.discard')}}</v-btn>
      <v-btn @click="check" :disabled="submit_btn" type="submit" class="button onPrimaryActions--text primaryActions small-button">{{$t('myLocations.saveAddress')}}</v-btn>
    </v-card-actions>
  </v-card>
  </v-form>
</template>

<script>
import { alertMessage } from '@/assets/messageAlert'
import { setAddress } from '@/views/adminPanel/models/address'
import {getProvinces, getProvinceCitys, zones} from "@/models/getProvinces"
import { toEnglishDigits } from '@/models/changeType'
import { mapState } from 'vuex'
  export default {
    created () {
      getProvinces()
      zones()
    },
    computed:{
      ...mapState({
        Provinces: state => state.address.Provinces,
        ProvincesObject: state => state.address.ProvincesObject,
        citysObject: state => state.address.citysObject,
        citys: state => state.address.citys,
        submit_btn: state => state.loadingSubmit,
        _zones: state => state.address.zones,
        zones_title: state => state.address.zones_title
      })
    },
    data: () => ({
      array: [],
      loading: false,
      address: {
        title: 'خانه',
        address: '',
        postal_code: '',
        mobile: '',
        phone: '',
        receiver: ''
      },
      Province: '',
      city: '',
      rules: {
        required: null,
        count: value => value.length <= 20 || alertMessage['MaxCharacters'],
        phone: value => {
          if (value) {
            const mobile = toEnglishDigits(value)
            return mobile.slice(0,2) == '05' || alertMessage['typePhone']
          }
        },
        mobile: value => {
          if (value) {
            const mobile = toEnglishDigits(value)
            return mobile.slice(0,2) == '09' || alertMessage['typePhone']
          }
        },
        address: value => value.length <= 200 || alertMessage['address'],
        post_code: value => {
          if (value) {
            const code = toEnglishDigits(value)
            const pattern = /\b(?!(\d)\1{3})[13-9]{4}[1346-9][013-9]{5}\b/
            return pattern.test(code) || alertMessage['post_code']
          }
        },
        type_number: value => {
          if (value) {
            const mobile = toEnglishDigits(value)
            const pattern = /^[0-9]+$/
            return pattern.test(mobile) || alertMessage['type_number']
          }
        },
        persian_text: v => {
          if (v) {
            const pattern = /^[\u0600-\u06FF\s0-9]+$/
            return pattern.test(v) || alertMessage['just_persian']
          }
        },
        just_text: v => {
          if (v) {
            const pattern = /^([^0-9]*)$/
            return pattern.test(v) || alertMessage['just_text']
          }
        },
        whitespace: v => {
          if (v) {
            const pattern = /.*\S.*/
            return pattern.test(v) || alertMessage['Required']
          }
        }
      }
    }),
    methods: {
      type_address (item) {
        this.type = item
      },
      check () {
        this.rules.required = value => !!value || alertMessage['Required']
      },
      saveAddress () {
        const validate = this.$refs.set_address.validate()
        let city = {}
        let province = {}
        for (const pre of this.ProvincesObject) {
          if (pre.value == this.Province) {
            province = pre
          }
        }
        for (const pre of this.citysObject) {
          if (pre.value == this.city) {
            city = pre
          }
        }
        if (validate == true) {
          const address = { province: province.key, province_name: province.value, city: city.key,
          city_name: city.value, phone: toEnglishDigits(this.address.phone),
          title: this.address.title, type: 1,
          mobile: toEnglishDigits(this.address.mobile), receiver: this.address.receiver,
          address: this.address.address, postal_code: toEnglishDigits(this.address.postal_code),
          latitude: 0, longitude: 0 }
          setAddress(address)
          this.$emit('back', false)
        }
      },
      getCity () {
        for (const pre of this.ProvincesObject) {
          if (pre.value == this.Province) {
            getProvinceCitys(pre.key)
          }
        }
      },
      back () {
        this.$emit('back', false)
      }
    },
  }
</script>