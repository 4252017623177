<template>
  <div>
  <v-card class="card mx-2 flat-card" color="surface">
    <discount/>
  </v-card>
  <div class="text-center mt-1" style="height:40px">
    <span class="vertical-line"></span>
  </div>
  <v-card color="accent" class="pa-1 mt-1 flat-card onAccent--text card text-center mx-auto" width="160">
    <div>
    <span class="medium-font numbers-font" v-text="after_coupon"></span>
    </div>
  </v-card>
  <div>
  <div class="text-center my-2">
    <div v-if="purses.length > 0">
  <div class="text-center my-2">
  <div class="text-center mt-1 mb-5" style="height:40px">
    <span class="vertical-line"></span>
  </div>
  <v-card class="card mx-2 flat-card" color="surface">
    <wallet/>
  </v-card>

  <div class="text-center mt-1" style="height:40px">
    <span class="vertical-line"></span>
  </div>

  <v-card class="pa-1 mt-1 card text-center mx-auto flat-card" width="160" color="accent">
    <div>
    <span class="medium-font numbers-font onAccent--text" v-text="after_purse"></span>
    </div>
  </v-card>
 </div>
 </div>
 <div v-if="payment_tah != 0">
  <div class="text-center mt-1" style="height:40px">
    <span class="vertical-line"></span>
  </div>

  <v-card class="card mx-2 flat-card" color="surface">
    <paymentWay/>
  </v-card>
  </div>
  <v-btn @click="payment_basket" class="onPrimaryActions--text primaryActions button mt-4" width="300">{{$t('payment.finishPayment')}}</v-btn>
 </div>
 </div>
  </div>
</template>
<script>
import wallet from './walletCard'
import discount from './discountCard'
import paymentWay from './paymentMethodCard'
import { mapState } from 'vuex'
import { changeTypePrice_factor } from '@/models/changeType'
import store from '@/store'
import { submit_pay } from '@/views/shop/models/basket'
export default {
   components:{
    wallet,
    discount,
    paymentWay
  },
  data: () => ({
    total: '',
    after_purse_payment: ''
  }),
  computed: {
    ...mapState({
      total_price: state => state.basket.payment_defult,
      after_coupon: state => state.basket.payment_after_coupon,
      after_purse: state => state.basket.payment_after_purse,
      purses: state => state.basket.purses,
      payment_tah: state => state.basket.cost_payment,
      coupons: state => state.basket.coupons,
      way_pay: state => state.basket.way_pay,
    }),
    loading () {
      let with_post = this.total_price.gross_price
      if (parseInt(this.total_price.gross_price) < parseInt(this.total_price.post_thershold)) {
        with_post = parseInt(this.total_price.gross_price) + parseInt(this.total_price.post_price_constant)
      }
      if (!with_post) {
        return true
      } else {
        return false
      }
    },
    payment () {
      let with_post = this.total_price.gross_price
      if (parseInt(this.total_price.gross_price) < parseInt(this.total_price.post_thershold)) {
        with_post = parseInt(this.total_price.gross_price) + parseInt(this.total_price.post_price_constant)
      }
      const total= changeTypePrice_factor({
          price: with_post,
          Tax_price: this.total_price.tax,
      })
      return total
    }
  },
  methods : {
    payment_basket () {
        let coupon = ''
        let purses = []
        if (this.coupons[0]) {
          coupon = this.coupons[0].id 
        }
      if (this.purses.length >= 1) {
        for (const purse of this.purses) {
          if (purse.use == true) {
            purses.push(purse.id)
          }
        }
      }
      if (this.payment_tah != 0) {
        if (!this.way_pay) {
          store.dispatch('alertError', {show: true, type: 'way_pay'})
          setTimeout(() => {
            store.dispatch('alertError', {show: false, type: 'way_pay'})
          }, 2500);
          return
        }
      }
      const payment = {
        coupons: coupon,
        purses: purses,
        pay_method: this.way_pay
      }
      submit_pay(payment)
    }
  }
}
</script>