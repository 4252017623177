type product = {
    count: string;
    price: string;
    Is_Bulk: string;
    Org_ID: string;
    id: string;
    image: string;
    max_request: string;
    Min_Order: string;
    Max_Order: string;
    onhand: string;
    title: string;
    unit_title: string;
    lot_size: string;
    price_gross: number;
}

const state = {
  purse: [],
  purse_info: [],
}

const mutations = {
    purse: (state,paylod) => {
        state.purse = paylod
      },
      purselist: (state,paylod) => {
        state.purse.push(paylod)
      },
      purseInfo: (state,paylod) => {
        state.purse_info = paylod
      },
      purseInfolist: (state,paylod) => {
          state.purse_info.push(paylod)
      },
}

const actions = {
    purse: ({commit}, paylod) => {
        commit('purse',paylod)
      },
      purselist: ({commit}, paylod) => {
        commit('purselist',paylod)
      },
      purseInfo: ({commit}, paylod) => {
        commit('purseInfo',paylod)
      },
      purseInfolist: ({commit}, paylod) => {
        commit('purseInfolist',paylod)
      },
}

export default { state, mutations, actions }