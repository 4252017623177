import axios from 'axios'
import Store from '@/store/index'
import{ changeTypePrice, changeTypecount, calOffer } from '@/models/changeType'
const token = localStorage.getItem('access_token')

export function getPurse() {
    Store.dispatch('purse', [])
    axios.post('/api/Accounting/Users/getPurses',{},
    {
        headers: {
        'Authorization': `Bearer ${token}`
        }
    }
    ).then(Response => {
      if (Response.data.Result.error) {
        Store.dispatch("alertSuccess",{
          show: true,
            type: 'error',
            typeMessage:'network',
            color:"error"
        })
        setTimeout(() => {
          Store.dispatch("alertSuccess",{
            show: false,
            type: 'success',
            typeMessage:'network',
            color:"success"
          })
        }, 3000);
        return
      }
      for (const purse of Response.data.Result) {
        const purse_info = {
          title: purse.Purse_Title,
          balance: purse.Balance,
          Purse_ID: purse.Purse_ID
        }
        Store.dispatch('purselist', purse_info)
        console.log(Response.data.Result)
    }
    }).catch (() => {
      // Store.dispatch('alertError', {show: true, type: 'network'})
  })
}

export function PurseView(purseID: string) {
    Store.dispatch('purseInfo', [])
    axios.post('/api/Accounting/Users/transactions',
    {
        "purseId": purseID
    },
    {
        headers: {
        'Authorization': `Bearer ${token}`
        }
    }
    ).then(Response => {
      if (Response.data.Result.error) {
        Store.dispatch("alertSuccess",{
          show: true,
            type: 'error',
            typeMessage:'network',
            color:"error"
        })
        setTimeout(() => {
          Store.dispatch("alertSuccess",{
            show: false,
            type: 'success',
            typeMessage:'network',
            color:"success"
          })
        }, 3000);
        return
      }
      for (const purse of Response.data.Result) {
        const purse_info = {
          id: purse.id,
          date: purse.date,
          income: purse.income,
          outgoing: purse.outgoing,
          balance: purse.balance
        }
        Store.dispatch('purseInfolist', purse_info)
    }
    }).catch (() => {
      // Store.dispatch('alertError', {show: true, type: 'network'})
  })
}