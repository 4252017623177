<template>
   <div class="d-flex align-center justify-start">
      <v-text-field placeholder="مقدار" dense flat type="tell"></v-text-field>
      <v-btn class="onPrimaryActions--text primaryActions  mr-1 px-0 button" :height="height">اعمال</v-btn>
   </div>
</template>
<script>
export default {
   props: ['height']
}
</script>