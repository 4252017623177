<template>
  <v-card class="cart-container drawer-card mt-2 pa-2" color="surface">
    <v-row class="d-flex flex-column align-center justify-center pa-2">
      <div>
        <span class="font onSurface--text">{{$t('product.totalPrice')}}</span>
      </div>
      <div>
        <span class="medium-font numbers-font primaryText--text ml-1" v-text="price"></span>
      </div>
    </v-row>
    <v-divider class="my-2"></v-divider>
    <div class="basket-drawer-btn">
      <v-btn @click="gobasket" class="button small-button onPrimaryActions--text primaryActions">{{$t('basket.showCart')}}</v-btn>
    </div>
    <v-row v-for="product in basket" :key="product.id" class="pa-2 mt-1 d-flex justify-center">
      <div style="width: 70px; height: 70px">
        <img :src="product.image" style="width: 100%; height: 100%">
      </div>
    </v-row>
  </v-card>
</template>

<script>
import { mapState} from 'vuex'
import { viewbasket_products } from '@/views/shop/models/basket'
import { changeTypePrice_format } from '@/models/changeType'
export default {
  created () {
    viewbasket_products()
  },
  computed: {
    ...mapState({
      basket: state => state.basket.basket,
      price (state) {
        const price = changeTypePrice_format({
        price: state.basket.priceBasket,
        Tax_price: '',
      })
        return price
      }
    })
  },
  methods: {
    gobasket () {
      this.$router.push('/basket')
    }
  }
}
</script>

