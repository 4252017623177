<template>
  <div>
    <star-rating :star-size="config.width" @rating-selected ="setRating" :show-rating='config.showRating' :rounded-corners='config.rounded'  :increment='config.increment'
    :read-only='config.readonly' :rating = 'rating' :max-rating='config.maxRating' :rtl='config.dir'></star-rating>
  </div>
</template>
<script>
import StarRating from 'vue-star-rating'
export default {
  components: {
    StarRating
  },
  props: ['config', 'rating'],
}
</script>