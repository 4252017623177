<template>
  <div>
    <v-card class="card pa-3 flat-card" color="surface">
      <v-row>
        <v-col>
          <span class="font primaryText--text">{{$t('product.receiver')}}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-1">
          <span class="medium-font onSurface--text" v-text="address.receiver"></span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="py-1">
          <span class="medium-font onSurface--text">{{$t('product.address')}} </span>
        </v-col>
        <v-col class="py-1">
          <span class="medium-font onSurface--text" v-text="address.address"></span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="py-1">
          <span class="medium-font onSurface--text">{{$t('auth.mobile')}} : </span>
        </v-col>
        <v-col class="py-1">
          <span class="numbers-font onSurface--text medium-font" v-text="address.mobail"></span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="py-1">
          <span class="medium-font onSurface--text">{{$t('auth.phone')}} : </span>
        </v-col>
        <v-col class="py-1">
          <span class="numbers-font onSurface--text medium-font" v-text="address.phone"></span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="py-1">
          <span class="medium-font onSurface--text">{{$t('product.deliveryTime')}} : </span>
        </v-col>
        <v-col class="py-1">
          <span class="medium-font onSurface--text" v-text="address.delivery"></span>
        </v-col>
      </v-row>
      </v-card> 
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed: {
      ...mapState({
        address: state => state.factors.factor_address_info,
      })
    },
}
</script>