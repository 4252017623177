<template>
  <div>
    <navbar/>
    <submenu @open_cat='open_cat'/>
    <div class="fixed-alert-adminPanel">
      <alert/>
    </div>
    <div class="section">
    <adminpanel class="display-none"/>
    <v-main class="main-container">
      <v-container>
        <v-row>
          <v-col>
            <inviteFriend/>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    </div>
    <drawer :openDrawer="drawer"/>
  </div>
</template>
<style lang="scss" scoped>
.section{
  padding-top: 30px;
}
</style>
<script>
import navbar from '@/components/navbar/actionBar'
import submenu from '@/components/navbar/subMenu'
import adminpanel from '@/components/adminPanel/drawer'
import drawer from '@/components/drawers/ordinateDrawer'
import inviteFriend from '@/components/adminPanel/inviteFriends'
import { getPurse } from '@/views/adminPanel/models/purse'
import { mapState } from 'vuex'
export default {
  components: {
    navbar,
    submenu,
    adminpanel,
    inviteFriend,
    drawer
  },
  data: () => ({
    drawer: false,
  }),
  methods:{
    open_cat (open) {
      this.drawer = open
    }
  },
  created () {
    getPurse()
  },
    computed: {
      ...mapState({
        purses: state => state.purse.purse
      })
    }
}
</script>