<template>
  <div >
    <v-expansion-panels v-model="panel" flat>
      <v-expansion-panel v-for="purse in purses" :key="purse.Purse_ID" @click="show_purce(purse)" >
        <v-expansion-panel-header>
          <v-row>
            <v-col cols="7" md="6">
              <div class="medium-font" v-text="purse.title"></div>
            </v-col>
            <v-col cols="4" md="3">
              <div>
                <span class="medium-font">{{$t('adminPanel.remaining')}} : </span>
                <span class="font numbers-font" v-text="purse.balance"></span>
              </div>
            </v-col>
          </v-row>
          <template v-slot:actions>
            <v-icon color="primary">
            $expand
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="mt-1 accent">
            <v-col cols="3">
              <span class="medium-font">{{$t('bill.date')}}</span>
            </v-col>
            <v-col cols="3">
              <span class="medium-font">{{$t('bill.received')}}<span class= "small-font">{{$t('bill.rial')}}</span></span>
            </v-col>
            <v-col cols="3">
              <span class="medium-font">{{$t('bill.payment')}}<span class="small-font">{{$t('bill.rial')}}</span></span>
            </v-col>
            <v-col cols="3">
              <span class="medium-font">{{$t('bill.remaining')}}<span class="small-font">{{$t('bill.rial')}}</span></span>
            </v-col>
          </v-row>
          <div class="mt-2" v-for="purse_ in purse_info" :key="purse_.id">
          <v-row >
            <v-col cols="3">
              <span class="medium-font numbers-font" v-text="purse_.date"></span>
            </v-col>
            <v-col cols="3">
              <span class="medium-font numbers-font" v-text="purse_.income"></span>
            </v-col>
            <v-col cols="3">
              <span class="medium-font numbers-font" v-text="purse_.outgoing"></span>
            </v-col>
            <v-col cols="3">
              <span class="medium-font numbers-font" v-text="purse_.balance"></span>
            </v-col>
          </v-row>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import { changeTypePrice_format } from '@/models/changeType'
import { PurseView } from '../../views/adminPanel/models/purse'
import { mapState } from 'vuex'
export default {
  computed: {
    // purse_config () {
    //   const balance = changeTypePrice_format({
    //     price: this.$props.purse.balance,
    //     Tax_price: '0'
    //   })
    //   return balance
    // },
    ...mapState({
      purse_info: state => state.purse.purse_info,
      purses: state => state.purse.purse
    })
  },
  methods: {
    show_purce (purse) {
      PurseView(purse.Purse_ID)
    }
  },
  data: () => ({
    panel: [],
  })
}
</script>