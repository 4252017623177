<template>
  <div>
    <navbar/>
    <submenu @open_cat='open_cat'/>
    <div class="fixed-alert">
      <alert/>
    </div>
    <div class="section">
      <detailLoader v-if="!product"/>
      <detail/>
      <tab/>
    </div>
    <drawer :openDrawer="drawer"/>
  </div>
</template>
<script>
import navbar from '@/components/navbar/actionBar'
import submenu from '@/components/navbar/subMenu'
import detail from '@/components/cards/productDetail/productDetailCard'
import detailLoader from '@/components/skeletonLoader/productDetailLoader'
import tab from '@/components/tabMenu/productDetailTabMenu'
import { viewProducts, similarProduct, viewbasket_products } from '../shop/models/basket'
import { comments_product } from './models/productsDetail'
import { mapState } from 'vuex'
import alert from '@/components/alerts/alert'
import drawer from '@/components/drawers/ordinateDrawer'
export default {
  props: ['productID'],
   components: {
      navbar,
      detail,
      submenu,
      tab,
      detailLoader,
      drawer,
      alert
   },
  data: () => ({
    drawer: false,
  }),
   created () {
      viewProducts(this.$props.productID)
      similarProduct(this.$props.productID)
      comments_product(this.$props.productID)
    },
    watch: {
      productsID: {
        handler: function () {
          const id = this.$props.productsID
          location.reload()
          viewProducts(id)
          comments_product(id)
          similarProduct(id)
        }
      }
    },
    computed: {
      ...mapState({
        product:state => state.basket.product_detail,
        similar: state => state.basket.similarProducts,
      })
    },
  methods:{
    open_cat (open) {
      this.drawer = open
    }
  }
}
</script>