<template>
<div>
  <v-card class="pa-2 my-3 radius-top-side flat-card " color="surface">
  <v-row class="mx-3">
    <v-col class="d-flex justify-start">
      <span class="base-font onSurface--text">{{$t('payment.wallets')}}</span>
    </v-col>
  </v-row>
  </v-card>
  <v-card v-for="purse in purses" :key="purse.id" class="pa-2 radius-bottom-side flat-card" color="surface">
    <v-row class="d-flex align-center mr-2">
      <v-col cols="8" class="pa-2 d-flex justify-start">
        <span class="medium-font mx-3 onSurface--text" v-text="purse.Purse_Title"></span>
      </v-col>
      <v-col class="pa-2 d-flex justify-start">
        <v-switch  @change="purseUse(purse)" v-model="purse.use" class="mx-3"></v-switch>
      </v-col>
    </v-row>
    <v-row class="d-flex align-center mr-2">
      <v-col cols="8" class="pa-2 d-flex justify-start">
        <span class="medium-font mx-3 onSurface--text">{{$t('payment.usableCredit')}} : </span>
      </v-col>
      <v-col class="pa-2 d-flex justify-start">
        <span class="medium-font mx-3 numbers-font onSurface--text" v-text="purse.balance_show"></span>
      </v-col>
    </v-row>
    <v-row class="d-flex align-center mr-2">
      <v-col cols="8" class="pa-2 d-flex justify-start">
        <span class="medium-font mx-3 onSurface--text">{{$t('payment.creditsUsed')}} : </span>
      </v-col>
      <v-col class="pa-2 d-flex justify-start">
        <span class="medium-font mx-3 numbers-font onSurface--text" v-text="purse.deducted"></span>
      </v-col>
    </v-row>
    <v-row class="d-flex align-center mr-2 mb-2">
      <v-col cols="8" class="pa-2 d-flex justify-start">
        <span class="medium-font mx-3 onSurface--text">{{$t('payment.creditsUsed')}} : </span>
      </v-col>
      <v-col class="pa-2 d-flex justify-start">
        <span class="medium-font mx-3 numbers-font onSurface--text" v-text="purse.remain"></span>
      </v-col>
    </v-row>
  </v-card>
</div>
</template>
<script>
import store from '@/store'
import { mapState } from 'vuex'
export default {
    data: () => ({
        wallets: [],
    }),
      computed: {
    ...mapState({
      purses: state => state.basket.purses, 
    })
  },
  methods: {
    purseUse (purse) {
      if (purse.use == true) {
        store.dispatch('cal_purse', purse)
      } else {
        store.dispatch('dont_use_purse', purse)
        store.dispatch('refresh_balance')
        for (const purse of this.purses) {
          if (purse.use) {
            store.dispatch('cal_purse', purse)
          }
        }
      }
    }
  }
}
</script>