<template>
  <v-card class="fixed-nav pt-2 px-2 card flat-card" color="surface">
    <div class="d-flex justify-center">
      <v-avatar size="120" tile>
        <v-img :src="logo"></v-img>
      </v-avatar>
    </div>
    <stepper/>
  </v-card>
</template>
<script>
import stepper from '@/components/stepper/basketStepper'
export default {
  components: {
    stepper
  },
  data: () => ({
    logo: ''
  }),
  created () {
    this.logo = localStorage.getItem('logo_header')
  }
}
</script>