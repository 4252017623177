<template>
    <splide :options="options">
      <splide-slide>
        <product/>
      </splide-slide>
      <splide-slide>
        <product/>
      </splide-slide>
      <splide-slide>
        <product/>
      </splide-slide>
    </splide>
</template>
<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import product from '@/components/cards/product/sliderCard'
export default {
   components: {
    Splide,
    SplideSlide,
    product
  },
   props: ['imageWidth', 'imageHeight'],
   data: () => ({
      options: {
        rewind : true,
        trimSpace: true,
        height: 320,
        perPage: 1,
        arrows: false,
        pagination: false,
        autoplay: true,
        type   : 'loop',
        gap: '0.5rem',
        start: 0,
        focus: 'center',
        direction: 'ttb',
      }
   })
}
</script>