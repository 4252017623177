<template>
    <div>
    <v-card class="pa-2 card flat-card ml-2" color="surface" width="260" height="400">
      <v-row @click="show_product()" class="pa-2 cursor-pointer">
        <v-col>
          <div style="width:190px; height:198px; ">
            <img :src="product.image" style="width:100%; height:100%; ">
          </div>
        </v-col>
        <discount v-if="product.offer" :offer ='product.offer'/>
      </v-row>
      <v-row class="mx-3 mt-0">
        <div @click="show_product()" class="title-container cursor-pointer">
          <span v-text="product.title.slice(0,70)"><span class="medium-font onSurface--text" v-if ="product.title > product.title.slice(0,70)">...</span></span>
        </div>
      </v-row>
      <v-row v-if="product.Is_Bulk == 1" class="mx-3">
        <v-col cols="7" class="pa-0">
          <span class="medium-font onSurface--text">{{$t('product.priceKg')}} : </span>
        </v-col>
        <v-col cols="5" class="pa-0">
          <span class="medium-font numbers-font onSurface--text" v-text="product.priceFormate"></span>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="5" class="pa-0"></v-col>
        <v-col cols="7" class="py-0">
          <span class="medium-font ml-2 error--text text-decoration-line-through numbers-font" v-if="product.price_gross != product.price && product.price_gross!= 0" v-text="product.priceGrossFormate"></span>
        </v-col>
      </v-row>
      <v-row class="mx-3 mb-3">
        <v-col cols="5" class="pa-0">
          <span class="medium-font onSurface--text">{{$t('product.packagePrice')}} : </span>
        </v-col>
        <v-col cols="7" class="pa-0">
          <span class="primaryText--text font numbers-font" v-if="product.Is_Bulk != 1" v-text="product.priceFormate"></span>
          <span class="primaryText--text medium-font numbers-font" v-if="product.Is_Bulk == 1" v-text="product.price_format_pack"></span>
        </v-col>
      </v-row>
      <v-row class="mx-3 mb-3 ">
      <div class="product-card-button">
        <div v-if="!add_basket" @click="add_basket = !add_basket">
          <btn :product= 'basket_product'/>
        </div>
        <div class="product-card-button">
          <quantityBtn color="secondaryActions onSecondaryActions--text" block="true" v-if="add_basket && product.Is_Bulk == 1" :product= 'basket_product' />
        </div>
        <plus v-if="add_basket && product.Is_Bulk != 1" :product= 'basket_product'/>
      </div>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import discount from '../../labels/discountLabel'
import btn from '../../buttons/productCardBtn'
import quantityBtn from '../../buttons/quantityBtn'
import plus from '../../buttons/plusNegativeBtn'
import { mapState } from 'vuex'
export default {
  props: ['product'],
  data: () => ({
    add_basket: false,
    basket_product: {}
  }),
  components: {
    discount,
    btn,
    quantityBtn,
    plus
  },
  computed: {
    ...mapState({
      basket: state => state.basket.basket,
    })
  },
  mounted () {
    for (const item of this.basket) {
      if (item.id == this.$props.product.id) {
        this.basket_product = item
        this.add_basket = true
        return
      }
    }
    this.basket_product = this.$props.product
  },
  methods: {
    show_product () {
      this.$router.push(`/productDetail/${this.$props.product.id}`)
    },
    add_basket_product () {
      console.log('click')
    }
  }
}
</script>