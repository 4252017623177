<template>
    <v-card class="pa-3 card flat-card w-100 my-auto" :color="color">
      <v-row>
        <v-col cols="4">
          <div style="width: 200px;">
            <img :src="slider.image" style="width: 100%">
          </div>
        </v-col>
        <v-col class="mx-4" align-self="center">
          <v-row class="mb-4">
            <v-col class="pb-0 d-flex justify-center">
              <div class="title-container px-2" style="width:78%">
                <span class="font font-weight-bold onSurface--text" v-text="slider.title" ></span>
              </div>
            </v-col>
            <discount v-if="slider.offer" :offer ='slider.offer'/>
          </v-row>
          <v-row>
            <v-col></v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0 d-flex flex-column align-center justify-center">
              <span class="base-font error--text font-weight-bold" v-text="slider.priceFormate" ></span>
              <div class="d-flex" v-if="slider.price_gross != slider.price && slider.price_gross!= 0">
                <span class="font text-decoration-line-through error--text font-weight-bold" v-text="slider.priceGrossFormate"></span>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0 d-flex align-center justify-center">
              <div v-if="!add_basket" @click="add_basket = !add_basket">
                <v-btn class="onSecondaryActions--text secondaryActions button" width="200">
                  <v-icon v-if="slider.onhand != 0" size="19" class="onSecondaryActions--text">mdi-plus</v-icon>
                  <span v-if="slider.onhand != 0" class="onSecondaryActions--text">سبد خرید</span>
                  <span v-if="slider.onhand == 0" class="onSecondaryActions--text">ناموجود</span>
                </v-btn>
              </div>
              <div class="product-card-button">
                <quantityBtn color="secondaryActions onSecondaryActions--text" block="true" v-if="add_basket && product.Is_Bulk == 1" :product= 'basket_product' />
              </div>
              <plus v-if="add_basket && product.Is_Bulk != 1" :product= 'basket_product'/>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
</template>
<script>
import { mapState } from 'vuex'
export default {
  props: ['color', 'slider'],
  computed: {
    ...mapState({
      basket: state => state.basket.basket,
    })
  },
  mounted () {
    for (const item of this.basket) {
      if (item.id == this.$props.product.id) {
        this.basket_product = item
        this.add_basket = true
        return
      }
    }
    this.basket_product = this.$props.product
  },
  methods: {
    show_product () {
      this.$router.push(`/productDetail/${this.$props.product.id}`)
    },
    add_basket_product () {
      console.log('click')
    }
  }
}
</script>