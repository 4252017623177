<template>
  <div>
    <v-container fluid>
      <navbar/>
      <submenu @open_cat='open_cat'/>
      <side class="fixed"/>
      <div class="section">
        <v-row class="d-flex justify-end mx-4">
          <v-card class="pa-2 d-flex mx-2 mb-2 card flat-card" color="surface">
            <slider/>
            <slider/>
            <slider/>
          </v-card>
          <v-card class="pa-2 d-flex mx-2 mb-2 card flat-card" color="surface">
            <slider/>
            <slider/>
            <slider/>
          </v-card>
        </v-row>
        <v-row class="d-flex justify-end mx-4">
          <v-card class="pa-2 d-flex mx-2 mb-2 card flat-card" color="surface">
            <slider/>
            <slider/>
            <slider/>
          </v-card>

          <v-card class="pa-2 d-flex mx-2 mb-2 card flat-card" color="surface">
            <slider/>
            <slider/>
            <slider/>
          </v-card>
        </v-row>
      </div>
    </v-container>
    <drawer :openDrawer="drawer"/>
  </div>
</template>
<style lang="scss" scoped>
.fixed{
  position: fixed;
  top: auto;
  bottom: 300px;
  right: 40px;
}
</style>
<script>
import side from '@/components/cards/subordinate/stickySideNav'
import navbar from '@/components/navbar/actionBar'
import slider from '@/components/sliders/subordinateVerticalSlider'
import submenu from '@/components/navbar/subMenu'
import drawer from '@/components/drawers/ordinateDrawer'
export default {
  components: {
    side,
    navbar, 
    slider,
    submenu,
    drawer
  },
  data: () => ({
    drawer: false,
  }),
  methods:{
    open_cat (open) {
      this.drawer = open
    }
  }
}
</script>