<template>
    <div>
    <v-stepper v-model="step" alt-labels class="mt-2 elevation-0">
      <v-stepper-header>
        <v-stepper-step @click="router('receiverData')" class="cursor-pointer medium-font" :complete="step > 1" step="1">
          {{$t('basket.setAddress')}}
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step @click="router('deliveryTime')" class="cursor-pointer medium-font" :complete="step > 2" step="2">
          {{$t('basket.setDeliveryTime')}}
        </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step @click="router('payment')" class="cursor-pointer medium-font" :complete="step > 3" step="3">
          {{$t('bill.paymentMethod')}}
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="4" class="medium-font">
          {{$t('basket.complete')}}
        </v-stepper-step>
      </v-stepper-header>

      <!-- <v-stepper-items>
        <v-stepper-content
          v-for="n in steps"
          :key="`${n}-content`"
          :step="n"
        >
          <v-card
            class="mb-12"
            color="grey lighten-1"
            height="200px"
          ></v-card>

          <v-btn
            color="primary"
            @click="nextStep(n)"
          >
            Continue
          </v-btn>

          <v-btn text>
            Cancel
          </v-btn>
        </v-stepper-content>
      </v-stepper-items> -->
    </v-stepper>
</div>
</template>
<script>
import { mapState } from 'vuex'
import { submitReciver, setTimeSend } from '@/views/shop/models/basket'
export default {
  computed: {
    ...mapState({
      address: state => state.basket.addressBasket,
      time_basket: state => state.basket.set_time_basket,
    }),
    step () {
      const route = this.$router.history.current.name
      let step = 1
      switch (route) {
        case 'receiverData':
           step = 1
          break;
          case 'deliveryTime':
          step = 2
          break;
          case 'payment':
          step = 3
          break;
      }
      return step
    }
  },
  methods: {
    router (route) {
      switch (route) {
          case 'deliveryTime':
            if (this.address) {
              submitReciver(this.addressBasket)
            }
          break;
        case 'payment':
          if (this.time_basket.length != 0) {
           setTimeSend(this.time_basket)
         }
          break;
          case 'receiverData':
          this.$router.push('/receiverData')
          break;
      }
    }
  }
}
</script>