<template>
	<v-btn class="onSecondaryActions--text secondaryActions btn-block button">
    <v-icon v-if="product.onhand != 0" size="19" class="onSecondaryActions--text">mdi-plus</v-icon>
    <span v-if="product.onhand != 0" class="onSecondaryActions--text">سبد خرید</span>
    <span v-if="product.onhand == 0" class="onSecondaryActions--text">ناموجود</span>
  </v-btn>
</template>
<script>
export default {
  props: ['product']
}
</script>