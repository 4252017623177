<template>
  <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
        type="button"
          :color="color"
          class=" button"
          :class ="block ? 'btn-block': ''"
          dark
          v-bind="attrs"
          v-on="on">
          <span class="onSecondaryActions--text" v-text="minOrder"></span><v-icon class="mr-auto onSecondaryActions--text">mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-virtual-scroll
      :items="selected"
      :item-height="50"
      height="300"
      class="surface"
    >
      <template v-slot:default="{ item }">
        <v-list-item>
          <v-list-item-content @click="addBasket(item)">
            <v-list-item-title  v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-virtual-scroll>
    </v-menu>
</template>
<style lang="scss" scoped>
.v-list-item__title{
  font-size: 15px !important;
}
</style>
<script>
import { mapState } from 'vuex'
import { addTObasket, removeTObasket } from '@/views/shop/models/basket'
  export default {
    data: () => ({
      selected: [{title: 'عدم انتخاب'}],
      minOrder: '',
      total: 0,
      Order_count: ''
    }),
    props: ['product', 'color', 'block'],
    computed: {
      ...mapState({
        addBasket_error: state => state.basket.addBasket_error,
        addBasket_status: state => state.basket.addBasket_status
      })
    },
    watch: {
    addBasket_status: {
      handler: function () {
        if ( this.addBasket_error == false) {
          this.minOrder = this.Order_count
        } else {
          if (this.minOrder.split(" ")[0] == parseInt(this.$props.slider.min_order) * parseFloat(this.$props.slider.Packing_Weight)) {
            this.$emit('show',false)
          }
        }
      }
    }
  },
      mounted () {
        if (this.$props.product.count == 0) {
          let order = parseInt(this.$props.product.min_order) * parseFloat(this.$props.product.Packing_Weight)
          const min_order_ = parseInt(this.$props.product.min_order) * parseFloat(this.$props.product.Packing_Weight)
          this.Order_count = order + ' کیلوگرم '
          let lot_size = parseInt(this.$props.product.lot_size)
          const Max_Order = Math.min(this.$props.product.onhand, this.$props.product.max_order)
          let min_order_while = parseInt(this.$props.product.min_order)
          addTObasket({product: { product_id: this.$props.product.id ,count: parseInt(this.$props.product.min_order), org_id: this.$props.product.org_id, weight: this.$props.product.Packing_Weight},
            type: '+',
            price: ((parseInt(this.$props.product.min_order)* this.$props.product.Packing_Weight) * (parseInt(this.$props.product.price)) - this.total),
            product_to_basket: this.$props.product
          })
          this.total = (parseInt(this.$props.product.min_order)* this.$props.product.Packing_Weight) * (parseInt(this.$props.product.price))
          while (min_order_while <= Max_Order) {
            const select = { title: (order).toFixed(1) + ' کیلوگرم ', id: this.$props.product.id }
            this.selected.push(select)
             lot_size = lot_size + parseInt(this.$props.product.lot_size)
             min_order_while = min_order_while + parseInt(this.$props.product.lot_size)
            order = min_order_ * lot_size
          }
        } else {
          this.total = this.$props.product.count * (parseInt(this.$props.product.price))
        let order = parseInt(this.$props.product.min_order) * parseFloat(this.$props.product.Packing_Weight)
        const min_order_ = parseInt(this.$props.product.min_order) * parseFloat(this.$props.product.Packing_Weight)
        this.minOrder = this.$props.product.count + ' کیلوگرم '
        let lot_size = parseInt(this.$props.product.lot_size)
        this.Order_count = order + ' کیلوگرم '
        const Max_Order = Math.min(this.$props.product.onhand, this.$props.product.max_order)
        let min_order_while = parseInt(this.$props.product.min_order)
          while (min_order_while <= Max_Order) {
            const select = { title: order + ' کیلوگرم ', id: this.$props.product.id }
            this.selected.push(select)
             lot_size = lot_size + parseInt(this.$props.product.lot_size)
             min_order_while = min_order_while + parseInt(this.$props.product.lot_size)
            order = min_order_ * lot_size
          }
        }
      },
      methods: {
        addBasket (item) {
          if (item.title == 'عدم انتخاب') {
            removeTObasket({product_id: this.$props.product.id, org_id: this.$props.product.org_id})
            this.$emit('show',false)
            return
          }
          const total = item.title.split(" ")[0]
          this.Order_count = item.title
          const count = parseFloat(item.title.split(" ")[0]) / parseFloat(this.$props.product.Packing_Weight)
        if (this.total > (parseInt(this.$props.product.price)) * total) {
          addTObasket({product: { product_id: this.$props.product.id ,
            count: count, org_id: this.$props.product.org_id, weight: this.$props.product.Packing_Weight},
            price: (this.total - (parseInt(this.$props.product.price)) * total), type: '-'})
            this.total = (parseInt(this.$props.product.price)) * total
        } else if(this.total < (parseInt(this.$props.product.price)) * total) {
          addTObasket({product: { product_id: this.$props.product.id ,
            count: count, org_id: this.$props.product.org_id, weight: this.$props.product.Packing_Weight },
            price: ((parseInt(this.$props.product.price)) * total - this.total), type: '+'})
            this.total = (parseInt(this.$props.product.price)) * total
        }
        }
      }
  }
</script>