
import axios from 'axios'
import Store from '@/store/index'
const token = localStorage.getItem('access_token')

export function getProvinces () {
    axios.get('/api/Store/Basket/getProvinces',{
        headers: {
        'Authorization': `Bearer ${token}`
        }
    }).then(Response => {
        if (Response.data.Result.error) {
            Store.dispatch('alertError', {show: true, type: 'network'})
            return
        }
        const key: any = []
        for (const iterator in Response.data.Result) {
            const object = {key: iterator, value: Response.data.Result[iterator]}
            key.push(object)
        }
      Store.dispatch('Provinces', key)
    }).catch (() => {
        // Store.dispatch('alertError', {show: true, type: 'network'})
    })
}

export function getProvinceCitys(provinceID: '') {
    axios.get(`/api/Store/Basket/getCities/${provinceID}`, {
        headers: {
        'Authorization': `Bearer ${token}`
        }
    }).then(Response => {
        if (Response.data.Result.error) {
            Store.dispatch('alertError', {show: true, type: 'network'})
            return
        }
        const citys: any = []
        for (const iterator in Response.data.Result) {
            const object = {key: iterator, value: Response.data.Result[iterator]}
            citys.push(object)
        }
        Store.dispatch('ProvinceCitys', citys)
    }).catch (() => {
        // Store.dispatch('alertError', {show: true, type: 'network'})
    })
}
export function zones() {
    axios.get(`/api/store/addresses/getZones`, {
        headers: {
        'Authorization': `Bearer ${token}`
        }
    }).then(Response => {
        if (Response.data.Result.error) {
            Store.dispatch('alertError', {show: true, type: 'network'})
            return
        }
        console.log(Response.data.Result)
        Store.dispatch('set_zone', Response.data.Result)
    }).catch (() => {
        // Store.dispatch('alertError', {show: true, type: 'network'})
    }) 
}
