<template>
  <div class="profile-drawer">
    <v-card class="card drawer-card pa-3" color="surface" width="250px">
      <v-row justify="center" class="mt-2">
        <v-avatar>
          <v-img :src="user.image"></v-img>
        </v-avatar>
      </v-row>
      <v-row class="pt-3 mb-2" justify="center">
        <span class="medium-font onSurface--text" v-text="user.nick_name"></span>
      </v-row>
      <v-divider class="mx-2 mb-2"></v-divider>
      <v-row class="pt-3 mx-3 mb-2" align="center">
        <v-icon class="onSurface--text">mdi-card-account-details-outline</v-icon>
        <span @click="panel('panel/userProfile')" class="medium-font mx-2 onSurface--text cursor-pointer">{{$t('adminPanel.userPanel')}}</span>
      </v-row>
      <v-divider class="mx-2 mb-2"></v-divider>
      <v-row class="pt-3 mx-3 mb-2" align="center">
        <v-icon class="onSurface--text">mdi-lock-outline</v-icon>
        <span @click="panel('panel/changePass')" class="medium-font mx-2 onSurface--text cursor-pointer" >{{$t('auth.changePass')}}</span>
      </v-row>
      <v-divider class="mx-2 mb-2"></v-divider>
      <v-row class="pt-3 mx-3 mb-2" align="center">
        <v-icon class="onSurface--text">mdi-logout</v-icon>
        <span @click="log_out" class="medium-font mx-2 onSurface--text cursor-pointer" >{{$t('auth.logout')}}</span>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import { mapState} from 'vuex'
import { logOut } from '@/views/auth/models/auth'
export default {
  computed: {
    ...mapState({
      user: state => state.user.user,
    })
  },
  methods: {
    panel (panel) {
      this.$router.push(`/${panel}`)
    },
    log_out () {
      logOut()
    }
  }
}
</script>