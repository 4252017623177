<template>
  <div>
    <v-card class="card flat-card pa-3" color="surface">
    <v-card v-for="item in items" :key="item.id" class="card flat-card" color="surface" >
      <v-row align="center">
        <v-col cols="2" class="pa-2">
          <v-avatar tile size="80" class="mx-3">
            <v-img :src="item.image"></v-img>
          </v-avatar>
        </v-col>
        <v-col cols="5">
          <span class="medium-font onSurface--text" v-text="item.title"></span>
        </v-col>
        <v-col class="2">
          <span class="medium-font onSurface--text"><span class=" numbers-font onSurface--text" v-text="item.count"></span> <span v-text="item.unit_title"></span></span>
        </v-col>
        <v-col cols="3" class="d-flex justify-end">
          <div class=" mx-3">
          <span class="medium-font numbers-font mx-1 onSurface--text" v-text="item.price"></span>
          </div>
        </v-col>
      </v-row>

      <v-divider class="my-3 mx-2"></v-divider>

    </v-card>
    </v-card>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import store from '@/store'
import { viewbasket_products } from '@/views/shop/models/basket'
import { changeTypePrice_format } from '@/models/changeType'
export default {
  created () {
    viewbasket_products()
  },
    computed: {
        ...mapState({
          items: function (state) {
            const basket = state.basket.basket
            let items = []
            for (const item of basket) {
              let count = parseFloat(item.count)
              let price = changeTypePrice_format({
                  price: item.price * item.count,
                  Tax_price: item.tax
                })
              const product = {
                title: item.title,
                image: item.image,
                count: count,
                unit_title: item.unit_title,
                price: price
              }
              items.push(product)
            }
            return items
          }
        })
    }
}
</script>