
const state = {
    categories: {},
    show_category_list: {}
  }
  
  const mutations = {
      categories_list: (state, payload) => {
          state.categories = payload
      },
      show_category_list: (state, payload) => {
        state.show_category_list = payload
    }
  }
  
  const actions = {
      categories_list: ({commit}, categories) => {
        let categories_list = [] as any
        for (const category of categories) {
          if (category.icon) {
            caches.open('category').then((cache) =>{
              cache.add(category.icon)
            })
          }
            const cat = {
              id: category.id,
              title: category.title,
              icon: category.icon,
              tag: category.tags,
              children: category.children
            }
            categories_list.push(cat)
        }
        commit('categories_list', categories_list)
      },
      show_category_list: ({commit}, categories) => {
        let categories_list = [] as any
        for (const category of categories) {
          if (category.icon) {
            caches.open('category').then((cache) =>{
              cache.add(category.icon)
            })
          }
            const cat = {
              id: category.id,
              title: category.title,
              icon: category.icon,
              tag: category.tags,
              children: category.children
            }
            categories_list.push(cat)
        }
        commit('show_category_list', categories_list)
      }
  }
  
  export default { state, mutations, actions }