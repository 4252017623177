<template>
  <div>
    <navbar/>
    <submenu @open_cat='open_cat'/>
    <div class="fixed-alert-adminPanel">
      <alert/>
    </div>
    <div class="section">
    <adminpanel class="display-none"/>
    <v-main class="main-container">
      <v-container>
        <v-row class="mx-3">
          <v-col cols="12" class="d-flex justify-center">
            <userinfo/>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    </div>
    <drawer :openDrawer="drawer"/>
  </div>
</template>
<style lang="scss" scoped>
.section{
  padding-top: 30px;
  padding-bottom: 30px;
}
</style>
<script>
import navbar from '@/components/navbar/actionBar'
import submenu from '@/components/navbar/subMenu'
import adminpanel from '@/components/adminPanel/drawer'
import userinfo from '@/components/adminPanel/profileEditForm'
import drawer from '@/components/drawers/ordinateDrawer'
import { getProfile } from '@/views/adminPanel/models/user'
export default {
  components: {
    navbar,
    submenu,
    adminpanel,
    userinfo,
    drawer
  },
  data: () => ({
    drawer: false,
  }),
  created () {
    getProfile()
  },
  methods:{
    open_cat (open) {
      this.drawer = open
    }
  }
}
</script>