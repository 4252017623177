<template>
  <div>
    <v-card class="card pa-3 flat-card" color="surface">
      <v-row>
        <v-col>
          <span class="font primaryText--text">{{$t('bill.costs')}}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-1">
          <span class="medium-font onSurface--text">{{$t('bill.orders')}} : </span>
        </v-col>
        <v-col class="py-1 d-flex justify-end">
          <span class="mx-1 numbers-font medium-font onSurface--text" v-text="cost.cost"></span>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-1">
          <span class="medium-font onSurface--text">{{$t('bill.shippingCost')}} : </span>
        </v-col>
        <v-col class="py-1 d-flex justify-end">
          <span class="mx-1 numbers-font medium-font onSurface--text" v-text="cost.post_price"></span>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-1">
          <span class="medium-font onSurface--text">{{$t('bill.payable')}} : </span>
        </v-col>
        <v-col class="py-1 d-flex justify-end">
          <span class="numbers-font medium-font onSurface--text" v-text="cost.costToPay"></span>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-1">
          <span class="font primaryText--text">{{$t('bill.paymentMethod')}}</span>
        </v-col>
      </v-row>
      <v-row v-for="settlement in cost.settlements" :key="settlement.description">
        <v-col class="py-1">
          <span class="medium-font onSurface--text" v-text="settlement.description"></span>
        </v-col>
        <v-col class="py-1 d-flex justify-end">
          <span class="numbers-font medium-font onSurface--text" v-text="settlement.amount"></span>
        </v-col>
      </v-row>
      </v-card> 
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed: {
      ...mapState({
        cost: state => state.factors.factor_const_info
      })
    },
}
</script>