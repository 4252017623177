<template>
  <v-row dir="ltr">
    <v-col cols="4" class="d-flex align-center">
      <captcha-code :captcha.sync="code" ref="captcha"></captcha-code>
      <v-btn @click="handleClick" icon small >
        <v-icon class="primaryText--text">mdi-refresh</v-icon>
      </v-btn>
    </v-col>
    <v-col align-self="center">
      <v-text-field maxlength="4" @keyup="check_capcha" :placeholder="$t('auth.captcha')" hide-details outlined dense v-model="captcha"></v-text-field>
    </v-col>
  </v-row>
</template>
<script>
import CaptchaCode from "vue-captcha-code"
export default {
  components: {
    CaptchaCode,
  },
  data: () => ({
    code: '',
    captcha: '',
    validcapcha: ''
  }),
  methods: {
    handleClick() {
      this.$refs.captcha.refreshCaptcha();
    },
    check_capcha () {
      if (this.code == this.captcha) {
        this.$emit("capchaing", true)
      } else {
        this.$emit("capchaing", false)
      }
    }
  }
}
</script>