
const state = {
  user: null,
  invite_list: null
}

const mutations = {
  setUser: (state,paylod) => {
    state.user = paylod
  },
  remove_image: (state, paylod) => {
    state.user.image = paylod
  },
  invite_list: (state, paylod) => {
    state.invite_list = paylod
  },
}

const actions = {
  setUser: ({commit}, paylod) => {
    commit('setUser',paylod)
  },
  remove_image: ({commit}, paylod) => {
    commit('remove_image', paylod)
  },
  invite_list: ({commit}, paylod) => {
    commit('invite_list', paylod)
  }
}

export default { state, mutations, actions }