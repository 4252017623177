<template>
  <v-card class=" pa-3 card flat-card" color="surface">
    <div v-for="info in product.evaluations" :key="info.key">
    <v-row v-if="info.value" class="my-2">
      <v-col cols="6" md="3">
        <span class="font onSurface--text" v-text="info.label"></span>
      </v-col>
      <v-col class="accent ml-5">
        <span class="font onSurface--text" v-text="info.value"></span>
      </v-col>
    </v-row>
    </div>
    <div v-for="(info, i) in product.attrib_tags" :key="i">
    <v-row v-if="info[0].value_title" class="my-2">
      <v-col cols="6" md="3">
        <span class="font onSurface--text" v-text="info[0].key_title"></span>
      </v-col>
      <v-col class="accent ml-5">
        <span class="font onSurface--text" v-text="info[0].value_title"></span>
      </v-col>
    </v-row>
    </div>
  </v-card>
</template>
<script>
export default {
  props:['product'],
  created () {
    console.log(this.$props.product)
  }
}
</script>