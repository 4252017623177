<template>
  <div>
    <navbar/>
    <div class="fixed-alert">
      <alert/>
    </div>
    <v-container fluid>
      <v-row class="mt-1 mx-1">
        <div @click="set_address" class="d-flex align-center">
          <v-icon class="cursor-pointer info--text">{{$t('categories.arrowR')}}</v-icon>
          <span class="info--text font cursor-pointer mx-1">{{$t('basket.backToAddress')}}</span>
        </div>
      </v-row>
      <v-row class="mx-2 pt-2 mb-4">
        <v-col cols="12" md="8" class="pa-0">
          <cargo/>
        </v-col>      
        <v-col cols="12" md="4" class="py-0 px-3">
          <cost :text="$t('bill.paymentMethod')"/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import navbar from '@/components/navbar/basketNav'
import cost from '@/components/cards/basket/costBasketCard'
import cargo from '@/components/cards/receiverCards/cargoCard'
import { view_times_deliveries } from './models/basket'
import store from '@/store'
import alert from '@/components/alerts/alert'
export default {
  components: {
    navbar,
    cost,
    cargo,
    alert
  },
  created () {
    view_times_deliveries()
      store.dispatch('clear_time')
  },
  methods: {
    set_address () {
      this.$router.push('/receiverData')
    }
  }
}
</script>