
const state = {
    factors: [''],
    factor_address_info: null,
    factor_const_info: null,
    cargos: [],
    cargoItems: [],
    cargo_status: {}
}

const mutations = {
  crago_status: (state, paylod) => {
    state.cargo_status = paylod
  },
    factors: (state,paylod) => {
        state.factors = paylod
      },
      factorslist: (state,paylod) => {
        state.factors.push(paylod)
      },
      factor_address: (state,paylod) => {
        state.factor_address_info = paylod
      },
      factor_const: (state,paylod) => {
        state.factor_const_info = paylod
      },
      cargos: (state,paylod) => {
        state.cargos = paylod
      },
      cargoslist: (state,paylod) => {
        const cargo = state.cargos.find(c => c.cargo_id == paylod.cargo_id)
        if (!cargo) {
          state.cargos.push(paylod)
        }
      },
      cargosItems: (state,paylod) => {
        state.cargoItems = paylod
      },
      cargolist: (state,paylod) => {
        state.cargoItems.push(paylod)
      },
}

const actions = {
  crago_status: ({commit}, paylod) => {
    commit('crago_status', paylod)
  },
    factors: ({commit}, paylod) => {
        commit('factors',paylod)
      },
      factorslist: ({commit}, paylod) => {
        commit('factorslist',paylod)
      },
      factor_address: ({commit}, paylod) => {
        commit('factor_address',paylod)
      },
      factor_const: ({commit}, paylod) => {
        commit('factor_const',paylod)
      },
      cargos: ({commit}, paylod) => {
        commit('cargos',paylod)
      },
      cargoslist: ({commit}, paylod) => {
        commit('cargoslist',paylod)
      },
      cargosItems: ({commit}, paylod) => {
        commit('cargosItems',paylod)
      },
      cargolist: ({commit}, paylod) => {
        commit('cargolist',paylod)
      },
}

export default { state, mutations, actions }