import Vue from 'vue'
import { get_config } from '@/models/changeType'
import VueRouter, { RouteConfig } from 'vue-router'
import shop from '../views/shop/store.vue'
import basket from '../views/shop/basket.vue'
import search from '../views/search/search.vue'
import productDetail from '../views/productDetail/productDetail.vue'
import subordinate from '../views/shop/subordinate.vue'
import subordinates from '../views/shop/subordinates.vue'
import login from '../views/auth/login.vue'
import register from '../views/auth/register.vue'
import forgetPassword from '../views/auth/forgetPass.vue'
import authentication from '../views/auth/authCode.vue'
import receiverData from '../views/shop/selectAddress.vue'
import payment from '../views/shop/payment.vue'
import deliveryTime from '@/views/shop/setDeliveryTime.vue'
import adminPanel from '@/views/adminPanel/adminPanel.vue'
import test from '@/views/test.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'shop',
    component: shop
  },
  {
    path: '/basket',
    name: 'basket',
    component: basket
  },
  {
    path: '/search',
    name: 'search',
    component: search
  },
  {
    path: '/productDetail/:productID',
    name: 'productDetail',
    component: productDetail,
    props: true
  },
  {
    path: '/subordinate',
    name: 'subordinate',
    component: subordinate
  },
  {
    path: '/subordinates',
    name: 'subordinates',
    component: subordinates
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/register',
    name: 'register',
    component: register
  },
  {
    path: '/forget_password',
    name: 'forget_password',
    component: forgetPassword
  },
  {
    path: '/authentication',
    name: 'authentication',
    component: authentication
  },
  {
    path: '/receiverData',
    name: 'receiverData',
    component: receiverData
  },
  {
    path: '/deliveryTime',
    name: 'deliveryTime',
    component: deliveryTime
  },
  {
    path: '/payment',
    name: 'payment',
    component: payment
  },
  {
    path: '/panel/:page',
    name: 'admin',
    component: adminPanel,
    props:true
  },
  {
    path: '/test',
    name: 'test',
    component: test
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  const logo =  localStorage.getItem('logo')
  if (logo) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('dynamic-favicon');
  link.id = 'dynamic-favicon';
  link.rel = 'shortcut icon';
  link.href = logo
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  let config = {} as any
  if (to.name == 'test' || to.name == 'search' || to.name == 'authentication' || to.name == 'shop' || to.name == 'forget_password' || to.name == 'productDetail' || to.name == 'basket' || to.name == 'login' || to.name == 'register') {
    config = localStorage.getItem('config')
    if (config) {
      config = JSON.parse(config)
      Vue.nextTick(() => {
        document.title = config.title
      })
      next()
    } else {
      get_config().then(() => {
        config = localStorage.getItem
        if (config) {
          config = JSON.parse(config)
        }
      })
      Vue.nextTick(() => {
        document.title = config.title
      })
      next()
    }
  } else {
    const token = localStorage.getItem('access_token')
    if (token) {
      config = localStorage.getItem('config')
    if (config) {
      config = JSON.parse(config)
      Vue.nextTick(() => {
        document.title = config.title
      })
      next()
    } else {
      get_config().then(() => {
        config = localStorage.getItem
        if (config) {
          config = JSON.parse(config)
        }
      })
      Vue.nextTick(() => {
        document.title = config.title
      })
      next()
    }
    } else {
      config = localStorage.getItem('config')
    if (config) {
      config = JSON.parse(config)
      Vue.nextTick(() => {
        document.title = config.title
      })
      next('/login')
    } else {
      get_config().then(() => {
        config = localStorage.getItem
        if (config) {
          config = JSON.parse(config)
        }
      })
      Vue.nextTick(() => {
        document.title = config.title
      })
      next('/login')
    }
    }
  }
})
export default router
