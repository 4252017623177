<template>
<div>
  <div>
    <!-- <div class="mx-1 mt-5">
      <v-card width="280" height="478" class="mx-2 py-2 card flat-card">
        <v-row class="py-3 px-3 mx-1">
          <span class="primaryText--text mt-1" v-text="block.title"></span>
        </v-row>
        <v-divider class="mt-4"></v-divider>
        <div class="px-2 pt-4">
          <v-img width="263" height="390" class="pa-2" :src="block.banner"></v-img>
        </div>
      </v-card>
    </div> -->
    <div class="mt-8">
      <!-- <div v-for="lists in blockList" :key='lists.blockID'>
        <v-row  v-if="lists.blockID == block.id" class="white mx-3 pa-2 radius-top-side" align="center" justify="space-between">
          <div class="d-flex align-center">
            <div @click="change_list(list)" v-for="list in lists.lists" :key='list.id' class="tab-item-container" :class="active.id == list.id ? 'active-tab': ''">
              <span class="medium-font primaryText--text" v-text="list.title"></span>
            </div>
          </div>
        </v-row>
      </div> -->
      <div id="category-slider">
        <v-slide-group multiple show-arrows>
          <v-slide-item v-for="lists in blockList" :key='lists.blockID'>
            <div v-for="list in lists.lists" :key="list.id">
              <v-btn :input-value="fild_active == 'all' ? true: false"  v-text="list.title"
              outlined 
              :color="fild_active == 'all' ? '': 'primary'" class="button mx-1" active-class="primary white--text">
              <v-avatar size="25" class="ml-1">
                <v-img :src="block.banner"/>
              </v-avatar>
            </v-btn>
            </div>
          </v-slide-item>
        </v-slide-group>
      </div>
      <v-divider class="mt-3 mx-3"></v-divider>
      <v-card class="radius-bottom-side flat-card pa-3 mx-3">
        <v-slide-group
            v-model="model"
            show-arrows
          >
          <v-slide-item v-if="products.length < 1">
            <v-skeleton-loader class="mx-1" v-bind="attrs" type="image, article, actions" width="260" height="400"></v-skeleton-loader>
          </v-slide-item>
          <v-slide-item v-if="products.length < 1">
            <v-skeleton-loader class="mx-1" v-bind="attrs" type="image, article, actions" width="260" height="400"></v-skeleton-loader>
          </v-slide-item>
          <v-slide-item v-if="products.length < 1">
            <v-skeleton-loader class="mx-1" v-bind="attrs" type="image, article, actions" width="260" height="400"></v-skeleton-loader>
          </v-slide-item>
          <v-slide-item v-if="products.length < 1">
            <v-skeleton-loader class="mx-1" v-bind="attrs" type="image, article, actions" width="260" height="400"></v-skeleton-loader>
          </v-slide-item>
          <div v-for="product in products"
            :key="product">
            <div class="d-flex" v-if="product.parentID == block.id">
              <v-slide-item
                v-for="pro in product.products"
                :key="pro.id"
              >
              <card :product = 'pro' />
              </v-slide-item>
            </div>
          </div>
        </v-slide-group>
        </v-card>
    </div>
  </div>
</div>
</template>
<style lang="scss" scoped>
.wrapper{
  display: grid;
  grid-template-rows: 1;
  grid-template-columns: 20% 80%;
}
</style>
<script>
import card from '../cards/product/sliderCard'
import { viewblock } from '../../views/shop/models/home'
import { viewbasket_products } from '../../views/shop/models/basket'
import { search_product_tag } from '../../views/shop/models/search'
import { mapState} from 'vuex'
import store from '@/store'
export default {
  props: ['block'],
  components: {
    card
  },
  data: () => ({
    model: null,
    active: {}
  }),
  computed: {
    ...mapState({
      blockList: function (state) {
        const lists = state.home.blockList
        return lists
      },
      products: state => state.home.blockProduct,
      selected_list: state => state.home.selected_list,
      search_fild: state => state.search.searchFild
    })
  },
  methods: {
    change_list (list_id) {
      this.active = list_id
      viewblock(list_id.id,this.$props.block.id)
      const basket = localStorage.getItem('basket')
        if (basket) {
          store.dispatch('basket_local', JSON.parse(basket))
        } else {
          viewbasket_products()
        
        }
    },
    show_more () {
      let filters = localStorage.getItem('filter_search')
        if (filters) {
          filters = JSON.parse(filters)
          filters.page = 1
        } else {
          filters = {}
          filters.page = 1
        }
      switch (this.active.filter_type) {
        case '1':
          filters.block_id = this.active.id
          filters.title = {title: this.active.title, value: 'block_id'}
          localStorage.setItem('filter_search', JSON.stringify(filters))
        setTimeout(() => {
          search_product_tag(filters)
        }, 500);
          break;
          case '2':
        filters.group = this.active.filter_search
        filters.title = {title: this.active.title, value: 'group'}
        localStorage.setItem('filter_search', JSON.stringify(filters))
        setTimeout(() => {
          search_product_tag(filters)
        }, 500);
          break;
          case '3':
        filters.tags = this.active.filter_search
        filters.title = {title: this.active.title, value: 'tags'}
        localStorage.setItem('filter_search', JSON.stringify(filters))
        setTimeout(() => {
          search_product_tag(filters)
        }, 500);
          break;
      }
    }
  }
}
</script>