<template>
  <div >
    <splide :slides ='slides' :options="primaryOptions" ref="primary">
      <splide-slide>
        <img :src="image"  class="border-radius-default img">
      </splide-slide>
      <splide-slide v-for="(slide, i)  in slides" :key="i">
        <img :src="slide" class="border-radius-default img">
      </splide-slide>
    </splide>
    
		<!-- Thumbnail slider -->
    <div class="mt-3">
    <splide :slides ='slides' :options="secondaryOptions" ref="secondary">
      <splide-slide>
        <img :src="image" class="border-radius-default">
      </splide-slide>
			<splide-slide v-for="(slide, i)  in slides" :key="i">
        <img :src="slide" class="border-radius-default">
      </splide-slide>
    </splide>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@media(min-width: 1263px) {
  .img{
    width: 288px;
    height: 288px;
  }
}
</style>
<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide'
  export default {
    props:['slides', "image"],
    components: {
      Splide,
      SplideSlide,
    },
		data() {
			return {
        primaryOptions: {
        type  : 'loop',
        width : '100%',
        height: '100%',
				pagination: false,
        direction: 'rtl',
        arrows: false,
        waitForTransition: false,
      },
        secondaryOptions: {
          type   : 'slide',
          rewind : true,
          width  : '100%',
          gap    : '1rem',
					pagination  : false,
          fixedWidth  : 80,
          fixedHeight : 80,
          cover  : true,
          focus  : 'center',
          isNavigation: true,
          direction: 'rtl',
          arrows: false,
          perPage: 6,
        },
			};
		},
		
		mounted() {
 // Set the sync target.
      this.$refs.primary.sync( this.$refs.secondary.splide );
    },
	}
</script>