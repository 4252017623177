<template>
    <v-tabs vertical>
      <v-tab>مرغ</v-tab>
      <v-tab>مرغ</v-tab>
      <v-tab>مرغ</v-tab>

      <v-tab-item class="background">
        <v-row class="mx-2">
            <v-col>
                <card/>
            </v-col>
            <v-col>
                <card/>
            </v-col>
            <v-col>
                <card/>
            </v-col>
            <v-col>
                <card/>
            </v-col>
        </v-row>

        <v-row class="mx-2">
            <v-col>
                <card/>
            </v-col>
            <v-col>
                <card/>
            </v-col>
            <v-col>
                <card/>
            </v-col>
            <v-col>
                <card/>
            </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item class="background">
        <v-row class="mx-2">
            <v-col>
                <card/>
            </v-col>
            <v-col>
                <card/>
            </v-col>
            <v-col>
                <card/>
            </v-col>
            <v-col>
                <card/>
            </v-col>
        </v-row>

        <v-row class="mx-2">
            <v-col>
                <card/>
            </v-col>
            <v-col>
                <card/>
            </v-col>
            <v-col>
                <card/>
            </v-col>
            <v-col>
                <card/>
            </v-col>
        </v-row>
      </v-tab-item>
      
      <v-tab-item class="background">
        <v-row class="mx-2">
            <v-col>
                <card/>
            </v-col>
            <v-col>
                <card/>
            </v-col>
            <v-col>
                <card/>
            </v-col>
            <v-col>
                <card/>
            </v-col>
        </v-row>

        <v-row class="mx-2">
            <v-col>
                <card/>
            </v-col>
            <v-col>
                <card/>
            </v-col>
            <v-col>
                <card/>
            </v-col>
            <v-col>
                <card/>
            </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs>
</template>
<script>
import card from '../cards/product/productCard'
export default {
    components: {
        card
    }
}
</script>