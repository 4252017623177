<template>
    <div class="d-flex flex-column align-center my-7">
      <v-row class="pa-2 d-flex justify-center">
        <div>
          <v-img width="100" :src="logo"></v-img>
        </div>
      </v-row>
      <v-row class="d-flex flex-column">
      <div>
        <alert/>
      </div>
      <v-form @submit.prevent="login_user()" ref="login">
      <v-card class="card flat-card pa-3" color="surface" width="400">
        <v-row>
          <v-col class="pb-0">
            <span class="primaryText--text lg-font">ورود</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0 pt-1">
            <v-text-field
              :rules="[rules.required, rules.count, rules.phone, rules.type_number]"
              v-model="user.mobile"
              label="شماره همراه"
              maxlength="11" type="tell"
              required
              prepend-icon="mdi-cellphone"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0 pt-1">
            <v-text-field
              :rules="[rules.required, rules.count, rules.password]"
              v-model="user.password"
              label="رمز عبور" 
              :type="showPassword ? 'text' : 'password'"
              prepend-icon="mdi-lock-outline"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0 pt-1">
            <v-checkbox hide-details label="مرا به خاطر بسپار"></v-checkbox>
          </v-col>
        </v-row>
        <captcha @capchaing="capchaing"/>
        <v-card-actions class="d-flex flex-column mt-3 px-0">
          <v-btn @click="check" :disabled="submit_btn" class="onPrimaryActions--text primaryActions button w-100" type="submit">ورود
          </v-btn>
          <div class="mt-4">
            <span @click="forget()" class="d-block mb-1 text-center medium-font info--text cursor-pointer">فراموشی رمز عبور</span>
            <span @click="register()" class="d-block text-center medium-font info--text cursor-pointer">میخواهم ثبت نام کنم</span>
          </div>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-row>
  </div>
</template>
<script>
import captcha from '@/components/captcha/captcha'
import { alertMessage } from '@/assets/messageAlert'
import { toEnglishDigits } from '@/models/changeType'
import { login } from './models/auth'
import alert from '@/components/alerts/alert'
import { mapState } from 'vuex'
import store from '@/store'
export default {
  components: {
    captcha,
    alert
  },
  data: () => ({
    showPassword: false,
    logo: '',
    cap: false,
    user: {
      mobile: '',
      password: ''
    },
    rules: {
      required: null,
      count: value => value.length <= 20 || alertMessage['MaxCharacters'],
      password: value => value.length >= 6 || alertMessage['min_password'],
      phone: v => {
        const mobile = toEnglishDigits(v)
        return mobile.slice(0,2) == '09' || alertMessage['typePhone']},
      type_number: value => {
        const mobile = toEnglishDigits(value)
        const pattern = /^[0-9]+$/
        return pattern.test(mobile) || alertMessage['type_number']
      },
    }
  }),
  created () {
    this.logo = localStorage.getItem('logo_site')
  },
  computed: {
    ...mapState({
      submit_btn: state => state.loadingSubmit
    })
  },
  methods: {
    capchaing (cap) {
      this.cap = cap
    },
    check () {
      this.rules.required =
      value => !!value || alertMessage['require']
    },
    login_user () {
      const validation = this.$refs.login.validate()
      if (this.cap) {
        if (validation == true) {
        login({username: toEnglishDigits(this.user.mobile), password: toEnglishDigits(this.user.password)})
      }
      } else {
        scrollTo(0,0)
        store.dispatch("alertSuccess",{
      show: true,
        type: 'error',
        typeMessage:'captcha',
        color:"error"
    })
    setTimeout(() => {
      store.dispatch("alertSuccess",{
      show: false,
        type: 'error',
        typeMessage:'network',
        color:"error"
    })
    }, 2500);
      }
    },
    register() {
      this.$router.push('/register')
    },
    forget() {
      this.$router.push('/forget_password')
    }
  }
}
</script>