<template>
  <div>
    <navbar/>
    <div class="fixed-alert">
      <alert/>
    </div>
    <v-container fluid>
      <v-row class="mt-1 mx-1">
        <div @click="toDelivery()" class="d-flex align-center">
          <v-icon class="info--text cursor-pointer">{{$t('categories.arrowR')}}</v-icon>
          <span class="info--text cursor-pointer font mx-1">{{$t('basket.backToTime')}}</span>
        </div>
      </v-row>
      <v-row class="mx-2 pt-2">
        <v-col cols="12" md="6" class="px-0">
          <v-card class="pa-3 card flat-card" color="surface">
            <v-row>
              <v-col>
                <span class="font">لیست اقلام</span>
              </v-col>
            </v-row>
            <v-divider class="my-3"/>
            <cargo/>
          </v-card>
          
        </v-col>
        <v-col cols="12" md="6" class="px-0">
          <costs class="mx-2"/>
          <payment/>
        </v-col>
      </v-row>
      <!-- <v-row class="mb-4">
        <v-spacer></v-spacer>
        <v-col cols="6" class="d-flex justify-center">
          <v-btn @click="payment" class="primary button" width="300">پرداخت و اتمام فرایند خرید</v-btn>
        </v-col>
      </v-row> -->
    </v-container>
  </div>
</template>
<script>
import navbar from '@/components/navbar/basketNav'
import cargo from '@/components/cards/payment/cargoSummaryCard'
import payment from '@/components/cards/payment/paymentComponents'
import costs from '@/components/cards/payment/costCard'
import { mapState } from 'vuex'
import store from '@/store'
import { viewbasket, submit_pay } from './models/basket'
import alert from '@/components/alerts/alert'
export default {
  components:{
    navbar,
    cargo,
    payment,
    costs,
    alert
  },
    created () {
      viewbasket()
    },
    computed: {
      ...mapState({
        loading: state => state.loading_page,
        purses: state => state.basket.purses,
        coupons: state => state.basket.coupons,
        way_pay: state => state.basket.way_pay,
        payment_tah: state => state.basket.cost_payment
      })
    },
    methods: {
      payment () {
        let coupon = ''
        let purses = []
        if (this.coupons[0]) {
          coupon = this.coupons[0].id 
        }
      if (this.purses.length >= 1) {
        for (const purse of this.purses) {
          if (purse.use == true) {
            purses.push(purse.id)
          }
        }
      }
      if (this.payment_tah != 0) {
        if (!this.way_pay) {
          store.dispatch('alertError', {show: true, type: 'way_pay'})
          setTimeout(() => {
            store.dispatch('alertError', {show: false, type: 'way_pay'})
          }, 2500);
          return
        }
      }
      const payment = {
        coupons: coupon,
        purses: purses,
        pay_method: this.way_pay
      }
      submit_pay(payment)
    },
    toDelivery(){
      this.$router.push('/deliveryTime')
    }
  }
}
</script>